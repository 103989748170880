import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyLoadSrcDirective} from "./lazy-load-src.directive";
import {LazyLoadService} from "./lazy-load.service";

const declarations = [
  LazyLoadSrcDirective
];

@NgModule({
  declarations,
  exports: declarations,
  imports: [
    CommonModule
  ]
})
export class LazyLoadModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LazyLoadModule,
      providers: [
        LazyLoadService
      ]
    };
  }
}
