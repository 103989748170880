import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {TransportService} from "../../../services/transport.service";
import {IUser} from "../../../types/User";
import {plainToClass} from "class-transformer";
import {PersonalData} from "../../../models/PersonalData";
import {Subscription} from "rxjs";

declare var Stripe: any;

@Component({
  selector: 'app-cart-personal',
  templateUrl: './cart-personal.component.html',
  styleUrls: ['./cart-personal.component.styl']
})
export class CartPersonalComponent implements OnInit, OnDestroy {
  @Output() submitForm = new EventEmitter<IUser>();
  public personalData: IUser = {};
  public paymentMethod: 'card' | 'applepay' | 'googlepay' = 'card';
  private subsriptions: Subscription[] = [];

  constructor(public auth: AuthService, private api: TransportService) {
  }

  ngOnInit() {
    this.personalData = plainToClass(PersonalData, this.auth.user || {});
    this.subsriptions.push(
      this.auth.user$.subscribe(
        (user) => {
          this.personalData = plainToClass(PersonalData, this.auth.user || {});
        }
      )
    );
    console.log('personal data', this.personalData);
  }

  ngOnDestroy(): void {
    this.subsriptions.map(s => s.unsubscribe());
  }

  onSubmit() {
    this.submitForm.emit(this.personalData);

  }

}
