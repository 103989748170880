import {
  AfterContentInit,
  Component, ContentChild,
  ContentChildren, ElementRef, EventEmitter,
  Input, Output,
  QueryList, TemplateRef, ViewChild
} from '@angular/core';
import {TabsTabComponent} from './tabs-tab/tabs-tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.styl']
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabsTabComponent) tabs: QueryList<TabsTabComponent>;
  @ViewChild('tabsTmp', {static: false}) tabsTmp: ElementRef;
  @Output() showHideTabs: EventEmitter<any> = new EventEmitter();
  @Output() showTabs: EventEmitter<any> = new EventEmitter();
  @Input() dynamic = false;
  @Input() theme;
  @Input() pad;
  @Input() title;

  constructor() {
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.show$.getValue());

    if (activeTabs.length === 0) {
      Promise.resolve(null).then(() => {
        this.selectTab(this.tabs.first);
      });
    }
  }

  selectTab(tab) {
    this.tabs.toArray().forEach(_tab => {
      if (_tab.show$.getValue()) {
        _tab.showHide.emit({value: false, data: _tab.data});
        this.showHideTabs.emit({value: false, data: _tab.data});
        _tab.hide.emit(_tab.data);
        _tab.show$.next(false);
      }
    });
    if (tab && !tab.show$.getValue()) {
      // const viewRef = tab.contentTemplate.createEmbeddedView(tab.contentTemplate);
      // viewRef.detectChanges();
      tab.show$.next(true);
      tab.showHide.emit({value: true, data: tab.data});
      tab.show.emit(tab.data);
      this.showHideTabs.emit({value: true, data: tab.data});
      this.showTabs.emit(tab.data);
    }
  }
}
