import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-floating-input-label',
  templateUrl: './floating-input-label.component.html',
  styleUrls: ['./floating-input-label.component.styl']
})
export class FloatingInputLabelComponent implements OnInit {
  @Input() value: any;
  @Input() name: string;

  constructor() { }

  ngOnInit() {
  }

}
