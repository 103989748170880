import { Pipe, PipeTransform } from '@angular/core';
import {IProductItem} from "./types/Entities";
import {ISettingsCommissionFee} from "./types/Settings";
import {CartService} from "./services/cart.service";

@Pipe({
  name: 'calcCommission'
})
export class CalcCommissionPipe implements PipeTransform {
  constructor(private cart: CartService) {
  }
  transform(item: IProductItem, total = false): any {
    return this.cart.calcCommission(item, total);
  }
}
