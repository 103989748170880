import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs/index";
import {IItem} from "../../scroll/scroll.component";
import {ISector} from "../../../types/ISector";

@Component({
  selector: 'app-nav-projects-nav',
  templateUrl: './nav-projects-nav.component.html',
  styleUrls: ['./nav-projects-nav.component.styl']
})
export class NavProjectsNavComponent implements OnInit {
  @Input() sectors: ISector[];
  @Output() sectorsChange = new EventEmitter<string[]>();
  @Output() allClick = new EventEmitter<void>();
  @ViewChild('allTag', {static: true}) allTag: ElementRef;

  public all = true;
  public sectorChange$ = new Subject<IItem>();
  public _sectors: string[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  changeSectors(sector: ISector) {
    const existIndex = this._sectors.findIndex(id => id === sector.id);
    if (existIndex >= 0) {
      // this._sectors.splice(existIndex, 1);
      this._sectors = [];
    } else {
      this._sectors = [sector.id];
      // this._sectors.push(sector.id);
    }
    // this._sectors = [...this._sectors];
  }

  onClick(e, item: ISector | 'all') {
    if (item === 'all') {
      this._sectors = [];
      // this.allClick.emit();
    } else {
      this.changeSectors(item as ISector);
      // this.sectorsChange.emit(this._sectors);
    }
    this.all = !this._sectors.length;
    this.sectorsChange.emit(this._sectors);
    const $tag = this.all ? this.allTag.nativeElement : e.currentTarget;
    this.sectorChange$.next({
      offsetLeft: $tag.offsetLeft,
      width: $tag.clientWidth
    });
  }

  sectorIsActive(sector: ISector): boolean {
    return this._sectors.some(id => id === sector.id);
  }
}
