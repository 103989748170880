import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, throwError} from "rxjs";
import {IProductItem, TDonationType} from "../types/Entities";
import {StorageService} from "./storage.service";
import {TransportService} from "./transport.service";
// import {StripeService} from '@nomadreservations/ngx-stripe';
import {ISettingsCommissionFee, ISettingsDonationOptions} from "../types/Settings";
import {LoggerService} from "./logger.service";
import {clone} from 'ramda';
import {IOrganisation, IUser} from "../types/User";
import {catchError, filter, finalize, map, mergeMap, shareReplay, tap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";

export interface IOrgCartItem {
  single_amount?: number;
  recuring_amount?: number;
  total_amount?: number;
  org?: IOrganisation;
  projectsIds?: string[];
}

export interface IOrgCartStorage {
  [key: string]: IOrgCartItem;
}

export interface IAmountInputPopup {
  show: boolean;
  item?: IProductItem;
}

export interface IAddRemoveItems {
  item?: IProductItem;
  rect?: ClientRect;
}

export interface ICartPanelView {
  $el?: HTMLElement;
  rect?: ClientRect;
}

declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cartChange$ = new Subject<any>();
  public addItem$ = new Subject();
  public removeItem$ = new Subject();
  public addRemoveItems$ = new Subject<IAddRemoveItems>();
  public cartRecalc$ = new Subject();
  public cartSync$ = new Subject();
  public syncItem$ = new Subject();
  public drop$ = new Subject();
  public init$ = new BehaviorSubject<boolean>(false);
  public cartPanelViewChange$ = new BehaviorSubject<ICartPanelView>(null);
  public donationsOptions$ = new Subject<ISettingsDonationOptions>();
  public amountInputPopup$ = new BehaviorSubject<IAmountInputPopup>({show: false});
  private recurringDonationsSource = new Subject<IProductItem[]>();
  public recurringDonations$ = this.recurringDonationsSource.asObservable();
  public recurringFetch$: Observable<IProductItem[]>;
  public items: IProductItem[] = [];
  public orgStorage: IOrgCartStorage = {};
  public donationsOptions: ISettingsDonationOptions;
  public comissionFee: ISettingsCommissionFee = {singlePaymentFee: 0, regularPaymentFee: 0};
  public user: IUser;
  public recurringDonations: IProductItem[];
  public total_amount = 0;
  public total_recuring_amount = 0;
  public total_single_amount = 0;
  public total_recuring_amount_fee = 0;
  public total_single_amount_fee = 0;
  public org_recuring_count = 0;
  public org_single_count = 0;
  public readonly timeToDropCart = 1000 * 60 * 15;

  // private stripe: StripeService,
  constructor(
    private storage: StorageService,
    private api: TransportService,
    private logger: LoggerService,
    private auth: AuthService) {
    combineLatest(
      this.api.getSettings('donation-options'),
      // this.api.getSettings('commission-fee')
    ).subscribe(
      ([donations,
         // commission
       ]) => {
        this.donationsOptions = donations as ISettingsDonationOptions;
        // this.comissionFee = commission as ISettingsCommissionFee;
        this.getFromStorage();
        this.calculate();
        this.init$.next(true);
        this.cartChange$.next();
        this.donationsOptions$.next(this.donationsOptions);
      }
    );
    this.checkAndSetTimeMark();
    this.cartChange$.subscribe(() => {
      this.setTimeMark();
      this.logger.l('cart changes', this.items);
      this.logger.l('cart changes', this.orgStorage);
      this.logger.l('cart changes ... total_amount', this.total_amount);
      this.logger.l('cart changes ... total_single_amount', this.total_single_amount);
      this.logger.l('cart changes ... total_recuring_amount', this.total_recuring_amount);
      this.logger.l('cart changes ... total_recuring_amount_fee', this.total_recuring_amount_fee);
      this.logger.l('cart changes ... total_single_amount_fee', this.total_single_amount_fee);
    });
    this.addRemoveItems$.subscribe(() => {
      this.saveToStorage();
    });
    this.syncItem$.subscribe(() => {
      this.saveToStorage();
    });
    this.auth.user$.subscribe(user => {
      this.user = user;
      this.logger.l('cart user', user);
    });
    this.auth.auth$.subscribe(() => {
      this.setRecurringDonations();
    });
    this.setRecurringDonations();
    // this.amountInputPopup$.subscribe((data) => {
    //   if (data.item) {
    //     this.addItem(data.item);
    //   }
    // });
  }

  addItem(product: IProductItem, rect?: ClientRect) {
    const existIndex = this.items.findIndex(p => p.targetId === product.targetId);
    const storageItem = this.orgStorage[product.organisation.id];
    const _product: IProductItem = clone({...product, user: this.user} as IProductItem);
    if (existIndex >= 0) {
      const item = this.items[existIndex];
      item.amount += product.amount;
      // storageItem.total_amount = item.amount;
    } else {
      this.items.push(_product);
    }
    if (storageItem) {
      product.type === 'recuring' ?
        storageItem.recuring_amount += product.amount :
        storageItem.single_amount += product.amount;
      storageItem.total_amount += product.amount;
    } else {
      this.orgStorage[product.organisation.id] = {
        single_amount: product.type === 'single' ? product.amount : 0,
        recuring_amount: product.type === 'recuring' ? product.amount : 0,
        total_amount: product.amount,
        org: clone(product.organisation),
        projectsIds: []
      };
    }
    if (product.project &&
      !this.orgStorage[product.organisation.id].projectsIds.find(id => id === product.project.id)
    ) {
      this.orgStorage[product.organisation.id].projectsIds.push(product.project.id);
    }
    this.items = [...this.items];
    this.calculate();
    this.addRemoveItems$.next({item: existIndex >= 0 ? clone(this.items[existIndex]) : _product, rect});
    this.cartChange$.next();
  }

  removeItem(product: IProductItem, force = false, rect?: ClientRect) {
    const existIndex = this.items.findIndex(p => p.targetId === product.targetId);
    const storageItem = this.orgStorage[product.organisation.id];
    let _item: IProductItem;
    if (existIndex >= 0) {
      _item = clone(this.items[existIndex]);
      if (force) {
        _item.amount = 0;
        this.items.splice(existIndex, 1);
        this.items = [...this.items];
      } else {
        const item = this.items[existIndex];
        item.amount -= product.amount;
        _item.amount -= product.amount;
        if (item.amount <= 0) {
          this.items.splice(existIndex, 1);
        }
      }
    }
    if (storageItem) {
      if (force) {
        delete this.orgStorage[product.organisation.id];
      } else {
        product.type === 'recuring' ?
          storageItem.recuring_amount -= product.amount :
          storageItem.single_amount -= product.amount;
        storageItem.total_amount -= product.amount;
        if (storageItem.total_amount <= 0) {
          delete this.orgStorage[product.organisation.id];
        } else if (product._project) {
          const existProjectId = storageItem.projectsIds.findIndex(id => id === product.project.id);
          if (existProjectId >= 0) {
            storageItem.projectsIds.splice(existProjectId, 1);
          }
        }
      }
    }
    this.items = [...this.items];
    this.calculate();
    this.addRemoveItems$.next({item: _item, rect});
    this.cartChange$.next();
  }

  updateCalculate(type: 'add' | 'remove', product: IProductItem) {
    const {regularPaymentFee, singlePaymentFee} = this.comissionFee;
    this.total_amount += product.amount;
    this.total_recuring_amount += product.type === 'recuring' ? product.amount : 0;
    this.total_single_amount += product.type === 'single' ? product.amount : 0;
    this.total_recuring_amount_fee +=
      product.type === 'recuring' ?
        regularPaymentFee ? ((product.amount * 100) * (regularPaymentFee / 100)) : product.amount : 0;
    this.total_single_amount_fee +=
      product.type === 'single' ?
        singlePaymentFee ? ((product.amount * 100) * (singlePaymentFee / 100)) : product.amount : 0;
  }

  calcCommission(item: IProductItem, total = false) {
    const fee = item.type === 'single' ? this.comissionFee.singlePaymentFee : this.comissionFee.regularPaymentFee;
    const feeResult = item.amount * fee / 100;
    if (total) {
      return item.amount - feeResult;
    }
    return feeResult;
  }

  calculate() {
    this.total_amount = 0;
    this.total_recuring_amount = 0;
    this.total_single_amount = 0;
    this.total_recuring_amount_fee = 0;
    this.total_single_amount_fee = 0;
    this.org_recuring_count = 0;
    this.org_single_count = 0;
    Object.keys(this.orgStorage).map(key => {
      const org = this.orgStorage[key];
      this.org_single_count += org.single_amount > 0 ? 1 : 0;
      this.org_recuring_count += org.recuring_amount > 0 ? 1 : 0;
    });
    this.items.map(product => {
      this.updateCalculate('add', product);
    });
  }

  getProductItem(targetId): IProductItem {
    const item = this.items.find(p => p.targetId === targetId);
    if (item) {
      return clone(item);
    } else {
      return null;
    }
  }

  changeItem(targetId: string, prop: keyof IProductItem, value) {
    const item = this.items.find(p => p.targetId === targetId);
    if (item) {
      item[prop] = value;
    }
  }

  changeItemType(item: IProductItem, type: TDonationType, amount?: number) {
    const _item = this.items.find(p => p.targetId === item.targetId);
    if (_item) {
      _item.type = type;
      const storageItem = this.orgStorage[item.organisation.id];
      if (amount >= 0) {
        if (type === 'single' && storageItem.recuring_amount > 0) {
          storageItem.recuring_amount -= amount;
          // this.total_recuring_amount -= amount;
        }
        if (type === 'recuring' && storageItem.single_amount > 0) {
          storageItem.single_amount -= amount;
          // this.total_single_amount -= amount;
        }

        if (type === 'single') {
          storageItem.single_amount = amount;
          // this.total_single_amount += amount;
        } else {
          storageItem.recuring_amount = amount;
          // this.total_recuring_amount += amount;
        }
      }
      this.addRemoveItems$.next(clone(_item));
      this.calculate();
      this.cartChange$.next();
    }
  }

  syncItem(product: IProductItem, not_delete = false): Observable<IProductItem> {
    const sync = (user): Observable<IProductItem> => {
      const existIndex = this.items.findIndex(p => p.targetId === product.targetId);
      const _item = this.items[existIndex];
      const _product = clone(_item || product);
      const newItemMap = (newItem) => {
        this.items[existIndex] = {..._item, id: newItem.id};
        this.syncItem$.next(clone(newItem));
        return clone(newItem);
      };
      const onError = () => throwError(_product);
      if (_item) {
        if (!not_delete && _item.id && _item.user.id === user.id) {
          return this.api.deleteDonation(_item.id).pipe(
            mergeMap(() => {
              if (_item.amount > 0) {
                return this.api.donate(_item).pipe(
                  map(newItemMap), catchError(onError)
                );
              } else {
                return of(clone(_item));
              }
            }), catchError(onError)
          );
        } else {
          return this.api.donate(_item).pipe(
            map(newItemMap), catchError(onError)
          );
        }
      } else {
        if (product && product.id) {
          return this.api.deleteDonation(product.id).pipe(
            mergeMap(() => of(_product)), catchError(onError)
          );
        } else {
          return of(_product);
        }
      }
    };
    return this.auth.getUser().pipe(mergeMap(sync));
  }

  sync(not_delete = false) {
    if (this.items && this.items.length) {
      this.items.map((item, i) => {
        this.syncItem(item, not_delete).toPromise();
      });
    }
  }

  reSync() {
    this.getFromStorage();
    this.drop(true, true).then(
      () => {
        this.sync(true);
      },
      () => {
        this.sync(true);
      }
    );
  }

  drop(sync = false, not_local = false): Promise<void> {
    const promises: Promise<void>[] = [];
    if (!not_local) {
      this.items = [];
      this.orgStorage = {};
      promises.push(new Promise<void>((resolve, reject) => {
        this.calculate();
        this.saveToStorage();
        resolve();
      }));
    }
    if (sync) {
      promises.push(this.api.dropCart().toPromise());
    }
    return Promise.all(promises).then(
      () => {
        this.drop$.next();
        this.cartChange$.next();
        this.addRemoveItems$.next();
      },
      () => {
        this.drop$.next();
        this.cartChange$.next();
        this.addRemoveItems$.next();
      }
    );
  }

  setRecurringDonations() {
    this.recurringDonations = null;
    this.auth.isLoggedIn().toPromise().then(isLoggedIn => {
      if (isLoggedIn) {
        this.getRecurringDonations().subscribe((items) => {
          this.recurringDonations = items;
          this.recurringDonationsSource.next(clone(items) as IProductItem[]);
          this.logger.l('recurring donations', items);
        });
      }
    });
  }

  getRecurring(org: IOrganisation, items?: IProductItem[]): IProductItem {
    return (items || []).find(d => d.organisation.id === org.id);
  }

  getRecurringDonations(): Observable<IProductItem[]> {
    return this.auth.isLoggedIn().pipe(
      mergeMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(null);
        }
        if (this.recurringDonations) {
          return of(this.recurringDonations);
        } else {
          this.recurringFetch$ = this.recurringFetch$ || this.api.getRecurringDonations(this.auth.user.id).pipe(
            map(data => (data[0] as IProductItem[]).filter(item => {
              switch (item.status) {
                // case 'checkout':
                // case 'success':
                case 'active':
                case 'paused':
                  return true;
                default:
                  return false;
              }
            })),
            catchError(() => of(null)),
            finalize(() => {
              this.recurringFetch$ = null;
            }),
            shareReplay(1)
          );
          return this.recurringFetch$;
        }
      })
    );
  }

  saveToStorage() {
    this.storage.setItem('cart_items', JSON.stringify(this.items || []));
    this.storage.setItem('cart_org_storage', JSON.stringify(this.orgStorage || {}));
  }

  getFromStorage() {
    const items = this.storage.getItem('cart_items');
    const orgStorage = this.storage.getItem('cart_org_storage');
    this.items = items ? JSON.parse(items) : [];
    this.orgStorage = orgStorage ? JSON.parse(orgStorage) : {};
  }

  checkAndSetTimeMark() {
    const d = new Date();
    const {storage} = this;
    const timeMark = storage.getItem('cart_time_mark');

    if (
      timeMark &&
      (d.getTime() - parseInt(timeMark, 10) >= this.timeToDropCart)
    ) {
      this.drop(true);
    }
    this.setTimeMark();
  }

  setTimeMark() {
    this.storage.setItem('cart_time_mark', new Date().getTime());
  }

  checkout(body: IUser) {
    return this.api.checkout(body).pipe(
      tap(
        data => {
          Stripe(environment.STRIPE_OPEN_KEY)
            .redirectToCheckout({
              sessionId: data.checkoutId,
              // successUrl: environment.STRIPE_SUCCESS_REDIRECT,
              // cancelUrl: environment.STRIPE_CANCEL_REDIRECT,
            })
            .then(
              (result) => {
                console.log(result);
              }
            );
        }
      )
    );
  }
}
