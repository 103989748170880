import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CartService} from "../../services/cart.service";
import {IUser} from "../../types/User";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.styl']
})
export class CartComponent implements OnInit {
  @Output() submitForm = new EventEmitter<IUser>();
  constructor(public cart: CartService) { }

  ngOnInit() {
  }

  onSubmit(data) {
    this.submitForm.emit(data);
  }
}
