import { Injectable } from '@angular/core';
import {ILanguage} from "../types/Entities";
import {LoggerService} from "./logger.service";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class LangService {
  public languages: ILanguage[] = [
    {val: 'ru', name: 'ru', icon: '/assets/img/rus.png'},
    {val: 'en', name: 'en', icon: '/assets/img/us.png'}
  ];
  public selectedLang: string;

  constructor(private logger: LoggerService, private translate: TranslateService, private storage: StorageService) { }

  switchLanguage(language: string) {
    this.selectedLang = language;
    this.translate.use(language);
    this.storage.setItem('language', language);
    // this.global.langChange.next(true);
    // this.logger.l(language);
  }
}
