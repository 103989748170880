import {Component, Input, OnInit} from "@angular/core";
import {IProductItem, TDonationType} from "../../../types/Entities";
import {CartService} from "../../../services/cart.service";
import {IPlusMinusData} from "../../plus-minus/plus-minus.component";

@Component({
  selector: 'app-cart-table-row',
  templateUrl: './cart-table-row.component.html',
  styleUrls: ['./cart-table-row.component.styl']
})
export class CartTableRowComponent implements OnInit {
  @Input() type: TDonationType;
  // @Input() item: {key: string; value: IOrgCartItem};
  @Input() item: IProductItem;

  // public productItem: IProductItem;
  private countChangeTimeout;

  constructor(public cart: CartService) { }

  ngOnInit() {
    // this.productItem = {
    //   targetId: this.item.value.org.id,
    //   amount: this.item.value.total_amount,
    //   _org: this.item.value.org,
    //   type: this.type,
    //   targetType: 'ORG'
    // };
  }

  onCountChange(data: IPlusMinusData) {
  }

  onIncrement(data: IPlusMinusData) {
    clearTimeout(this.countChangeTimeout);
    this.countChangeTimeout = setTimeout(() => {
      // this.cart.addItem(this.item);
      this.cart.addItem({...this.item, amount: data.value - this.item.amount });
      // this.cart.syncItem(this.item.targetId).toPromise();
      // this.productItem.amount = data.value;
    }, 400);
  }

  onDecrement(data: IPlusMinusData) {
    clearTimeout(this.countChangeTimeout);
    this.countChangeTimeout = setTimeout(() => {
      // this.cart.removeItem(this.item);
      this.cart.removeItem({...this.item, amount: this.item.amount - data.value});
      // this.cart.syncItem(this.item.targetId).toPromise();
      // this.productItem.amount = data.value;
    }, 400);
  }

  onDelete() {
    this.cart.removeItem(this.item, true);
  }
}
