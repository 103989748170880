import {Component, Input, OnInit} from '@angular/core';
import {IInstanceEvent, TInstanceStatus} from "../../types/Entities";

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.styl']
})
export class StatusMessageComponent implements OnInit {
  @Input() event: IInstanceEvent;
  @Input() status: TInstanceStatus;
  @Input() sz = '1';

  constructor() { }

  ngOnInit() {
  }

}
