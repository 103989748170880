import { Component, OnInit } from '@angular/core';
import {TransportService} from "../../services/transport.service";
import {IContentHome, IContentWhats} from "../../types/Content";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.styl']
})
export class AboutComponent implements OnInit {
  public data: IContentWhats;
  public home: IContentHome;

  constructor(private api: TransportService) { }

  ngOnInit() {
    this.api.getSettings('content-whats').subscribe((data: IContentWhats) => this.data = data);
    this.api.getSettings('content-home').subscribe((data: IContentHome) => this.home = data);
  }

}
