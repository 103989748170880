import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "../app-routing.module";
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DeviceDetectorModule} from "ngx-device-detector";
import {NgxCurrencyModule} from "ngx-currency";
import {AuthInterceptor} from "../interceptors/auth-interceptor";
import {ErrorInterceptor} from "../interceptors/error-interceptor";
import {LoggerService} from "../services/logger.service";
import {LangService} from "../services/lang.service";
import {StorageService} from "../services/storage.service";
import {TransportService} from "../services/transport.service";
import {AuthService} from "../services/auth.service";
import {LayoutComponent} from "../components/layout/layout.component";
import {HeaderComponent} from "../components/header/header.component";
import {CartPanelComponent} from "../components/cart-panel/cart-panel.component";
import {SvgDefsComponent} from "../components/svg-defs/svg-defs.component";
import {SvgRefComponent} from "../components/svg-ref/svg-ref.component";
import {LangSelectComponent} from "../components/lang-select/lang-select.component";
import {MultilangFieldPipe} from "../pipes/multilang/multilang_field.pipe";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {AppComponent} from "../app.component";
import {AuthComponent} from "../components/auth/auth.component";
import {AuthPopupComponent} from "../components/auth/auth-popup/auth-popup.component";
import {AuthSignInComponent} from "../components/auth/auth-sign-in/auth-sign-in.component";
import {AuthSignUpComponent} from "../components/auth/auth-sign-up/auth-sign-up.component";
import {AuthForgetEnterComponent} from "../components/auth/auth-forget-enter/auth-forget-enter.component";
import {AuthForgetResetComponent} from "../components/auth/auth-forget-reset/auth-forget-reset.component";
import {AuthSignUpPasswordComponent} from "../components/auth/auth-sign-up-password/auth-sign-up-password.component";
import {AmountInputPopupComponent} from "../components/amount-input-popup/amount-input-popup.component";
import {TabsModule} from "../modules/tabs/tabs.module";
import {NativeElementControlDirective} from "../directives/native-element-control.directive";
import {FocusInputDirective} from "../directives/focus-input.directive";
import {FundCartItemComponent} from "../components/fund-cart-item/fund-cart-item.component";
import {ClickOutsideDirective} from "../directives/click-outside.directive";
import {ToggleClassDirective} from "../directives/toggle-class.directive";
import {PhoneValidatorDirective} from "../validators/directives/phone-validator.directive";
import {DtValidatorDirective} from "../validators/directives/dt-validator.directive";
import {EmailValidatorDirective} from "../validators/directives/email-validator.directive";
import {DropFilesDirective} from "../directives/drop-files.directive";
import {SafePipe} from "../pipes/safe/safe.pipe";
import {ProjectCategoryPipe} from "../pipes/project-category/project-category.pipe";
import {ImageTypePipe} from "../pipes/image-type/image-type.pipe";
import {SectorTypePipe} from "../pipes/sector-type/sector-type.pipe";
import {ProjectStatusPipe} from "../pipes/project-status/project-status.pipe";
import {SectorsFilterPipe} from "../pipes/sectors-filter/sectors-filter.pipe";
import {ProjectOrgSectorsFilterPipe} from "../pipes/project-org-sectors-filter/project-org-sectors-filter.pipe";
import {FilterOrgCartItemPipe} from "../pipes/filter-org-cart-item/filter-org-cart-item.pipe";
import {FilterProductTypePipe} from "../pipes/filter-product-type/filter-product-type.pipe";
import {DateSuffixPipe} from "../pipes/date-suffix/date-suffix.pipe";
import {IndexComponent} from "../pages/index/index.component";
import {CompanyHeadingComponent} from "../components/company-heading/company-heading.component";
import {NavProjectsComponent} from "../components/nav-projects/nav-projects.component";
import {FundItemComponent} from "../components/fund-item/fund-item.component";
import {ProjectItemComponent} from "../components/project-item/project-item.component";
import {SupportComponent} from "../components/support/support.component";
import {PeopleComponent} from "../components/people/people.component";
import {NavProjectsNavComponent} from "../components/nav-projects/nav-projects-nav/nav-projects-nav.component";
import {AboutComponent} from "../pages/about/about.component";
import {FundComponent} from "../pages/fund/fund.component";
import {ProjectComponent} from "../pages/project/project.component";
import {ProjectsListComponent} from "../components/projects-list/projects-list.component";
import {FundsListComponent} from "../components/funds-list/funds-list.component";
import {StatusMessageComponent} from "../components/status-message/status-message.component";
import {CheckoutComponent} from "../pages/checkout/checkout.component";
import {PlusMinusComponent} from "../components/plus-minus/plus-minus.component";
import {CartComponent} from "../components/cart/cart.component";
import {CartPersonalComponent} from "../components/cart/cart-personal/cart-personal.component";
import {CartPersonalFormComponent} from "../components/cart/cart-personal/cart-personal-form/cart-personal-form.component";
import {CheckoutResultComponent} from "../pages/checkout-result/checkout-result.component";
import {DateInputsComponent} from "../components/date-inputs/date-inputs.component";
import {ProjectUrgentItemComponent} from "../components/project-urgent-item/project-urgent-item.component";
import {SwitchTabsComponent} from "../components/switch-tabs/switch-tabs.component";
import {SectorsInputComponent} from "../components/sectors-input/sectors-input.component";
import {ValuesTextAreaComponent} from "../components/values-text-area/values-text-area.component";
import {ProjectMembersComponent} from "../components/project-members/project-members.component";
import {MembersWrapperComponent} from "../components/members-wrapper/members-wrapper.component";
import {CartTableRowComponent} from "../components/cart/cart-table-row/cart-table-row.component";
import {CartTableRowTotalComponent} from "../components/cart/cart-table-row-total/cart-table-row-total.component";
import {SwiperModule} from "ngx-swiper-wrapper";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {TextareaAutosizeModule} from "ngx-textarea-autosize";
import {ScrollModule} from "../modules/scroll/scroll.module";
import {QuillModule} from "ngx-quill";
import {RouterModule} from "@angular/router";
import {LangModule} from "../pipes/multilang/lang.module";
import {CartService} from "../services/cart.service";
import {FormService} from "../services/form.service";
import {ContactsComponent} from "../contacts/contacts.component";
import {FloatingInputLabelComponent} from "../floating-input-label/floating-input-label.component";
import {CartPanelCartComponent} from "../components/cart-panel/cart-panel-cart/cart-panel-cart.component";
import {NumerativeModule} from "../pipes/numerative/numerative.module";
import {AutoFontSizeDirective} from "../directives/auto-font-size.directive";
import {ViewInitDirective} from "../view-init.directive";
import {MatMenuModule} from "@angular/material";
import {SliceUrlPipe} from "../slice-url.pipe";
import {CalcCommissionPipe} from "../calc-commission.pipe";
import {LazyLoadModule} from "../modules/lazy-load/lazy-load.module";

const declarations = [
  AppComponent,
  IndexComponent,
  LangSelectComponent,
  HeaderComponent,
  SvgDefsComponent,
  SvgRefComponent,
  LayoutComponent,
  CompanyHeadingComponent,
  NavProjectsComponent,
  FundItemComponent,
  ProjectItemComponent,
  SupportComponent,
  CartPanelComponent,
  CartPanelCartComponent,
  PeopleComponent,
  NavProjectsNavComponent,
  AboutComponent,
  FundComponent,
  ProjectComponent,
  ProjectsListComponent,
  FundsListComponent,
  AuthComponent,
  AuthPopupComponent,
  AuthSignInComponent,
  AuthSignUpComponent,
  NativeElementControlDirective,
  FocusInputDirective,
  AuthForgetEnterComponent,
  AuthForgetResetComponent,
  AuthSignUpPasswordComponent,
  FundCartItemComponent,
  ClickOutsideDirective,
  AutoFontSizeDirective,
  ViewInitDirective,
  StatusMessageComponent,
  ProjectMembersComponent,
  CheckoutComponent,
  PlusMinusComponent,
  CartComponent,
  CartPersonalComponent,
  CartPersonalFormComponent,
  CheckoutResultComponent,
  DateInputsComponent,
  ToggleClassDirective,
  ProjectUrgentItemComponent,
  SwitchTabsComponent,
  PhoneValidatorDirective,
  DtValidatorDirective,
  EmailValidatorDirective,
  DropFilesDirective,
  SafePipe,
  SectorsInputComponent,
  ValuesTextAreaComponent,
  MembersWrapperComponent,
  ProjectCategoryPipe,
  ImageTypePipe,
  SectorTypePipe,
  ProjectStatusPipe,
  SectorsFilterPipe,
  ProjectOrgSectorsFilterPipe,
  FilterOrgCartItemPipe,
  CartTableRowComponent,
  CartTableRowTotalComponent,
  FilterProductTypePipe,
  DateSuffixPipe,
  SliceUrlPipe,
  CalcCommissionPipe,
  AmountInputPopupComponent,
  ContactsComponent,
  FloatingInputLabelComponent
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    LangModule,
    TranslateModule,
    MatMenuModule,
    SwiperModule,
    InfiniteScrollModule,
    DeviceDetectorModule.forRoot(),
    TextareaAutosizeModule,
    TabsModule,
    ScrollModule,
    QuillModule.forRoot(),
    NgxCurrencyModule.forRoot({
      align: 'left', precision: 0, prefix: '$ ', allowNegative: false, allowZero: true,
      decimal: '.', nullable: true, suffix: '', thousands: ','
    }),
    NumerativeModule,
    LazyLoadModule.forRoot()
  ],
  exports: declarations
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        LoggerService,
        LangService,
        TranslateService,
        TranslateStore,
        StorageService,
        TransportService,
        CartService,
        AuthService,
        FormService,
        // LazyLoadService
      ],
    };
  }
}
