import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from "./pages/index/index.component";
import {AboutComponent} from "./pages/about/about.component";
import {ProjectComponent} from "./pages/project/project.component";
import {FundComponent} from "./pages/fund/fund.component";
import {AuthGuard} from "./guards/auth.guard";
import {CheckoutComponent} from "./pages/checkout/checkout.component";
import {CheckoutResultComponent} from "./pages/checkout-result/checkout-result.component";
import {ContactsComponent} from "./contacts/contacts.component";

const routes: Routes = [
  {path: '', component: IndexComponent},
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'fund/:id', component: FundComponent },
  { path: 'project/:id', component: ProjectComponent },
  // { path: 'profile/:id', component: ProfileComponent},
  { path: 'profile/:id', loadChildren: './modules/profile/profile.module#ProfileModule', canActivate: [AuthGuard] },
  { path: 'confirm/:confirmWay', component: IndexComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'checkout/result', component: CheckoutResultComponent },
  // { path: '**', component: IndexComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
