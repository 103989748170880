import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {IContentHome} from '../../types/Content';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-company-heading',
  templateUrl: './company-heading.component.html',
  styleUrls: ['./company-heading.component.styl']
})
export class CompanyHeadingComponent implements OnInit, OnChanges {
  @Input() data: IContentHome;
  type: 'video' | 'youtube' | 'image';
  meta: any;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.data && changes.data.currentValue && changes.data.currentValue.videoURL ) {
      if ( /(?:mp4|ogv)$/.test(changes.data.currentValue.videoURL) ) {
        this.type = 'video';
      }
      if ( /^(?:https:\/\/)?(?:(?:www|m)\.)?youtube\.com/.test(changes.data.currentValue.videoURL) ) {
        this.type = 'youtube';
        const url = changes.data.currentValue.videoURL;
        const id = url.replace(/^.+v=([^&]+)(?:.+)?/g, '$1');
        this.meta = {
          id,
          embed: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + id + '?origin=https://youtubeembedcode.com'),
        };
      }
      if (/(?:jpg|jpeg|png)$/.test(changes.data.currentValue.videoURL)) {
        this.type = 'image';
      }
    }
  }

}
