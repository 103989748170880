import { Component, OnInit } from '@angular/core';
import {IProject} from "../../types/IProject";
import {TransportService} from "../../services/transport.service";
import {ActivatedRoute} from "@angular/router";
import {IOrganisation} from "../../types/User";
import {ISector} from "../../types/ISector";
import {IMember} from "../../types/IMember";
import {IProductItem} from '../../types/Entities';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['./fund.component.styl']
})
export class FundComponent implements OnInit {
  public projects: IProject[];
  public data: IOrganisation;
  public sectors: ISector[];
  public donations: [IProductItem[], number];
  public members: IMember[];
  public donationsLimit = 9;

  constructor(private api: TransportService, private route: ActivatedRoute, public global: GlobalService) { }

  ngOnInit() {
    this.global.isDesktop ? this.donationsLimit = 9 : this.donationsLimit = 5;
    this.route.params.subscribe((params) => {
      this.api.getOrganisations({id: params.id, details: 'sectors,country,images'})
        .toPromise().then((data) => this.data = data as IOrganisation);
      // this.api.getProjects({orgId: params.id}).subscribe(projects => this.projects = projects[0]);
      // this.api.getSectors({targetId: params.id, type: 'organisation'})
      //   .toPromise().then((sectors) => this.sectors = sectors);
      // this.api.getDonations({targetId: params.id, limit: this.donationsLimit, details: 'user,images,no-session'})
      //   .toPromise().then((donations) => {
      //     const _donations: IProductItem[] = [];
      //     let count = donations[1];
      //     donations[0].map(d => {
      //       if (!_donations.some(_d => _d.user.id === d.user.id)) {
      //         _donations.push(d);
      //       } else {
      //         count--;
      //       }
      //     });
      //     this.donations = [_donations, count];
      //   });
      this.api.getMembers(params.id)
        .toPromise().then((data) => this.members = data[0] as IMember[]);
    });
  }
}
