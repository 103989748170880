import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {LazyLoadService} from "./lazy-load.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[appLazyLoadSrc]'
})
export class LazyLoadSrcDirective implements AfterViewInit {
  @Input() appLazyLoadSrc: string;
  @Input() lazyBg = false;
  private intersect: Subscription;
  constructor(private lazyLoad: LazyLoadService, private elRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.intersect = this.lazyLoad.elIntersect$.subscribe(el => {
      if (el.ref.nativeElement === this.elRef.nativeElement) {
        const $el = el.ref.nativeElement;
        if (el.bg) {
          this.renderer.setStyle($el, 'background-image', `url(${el.src})`);
        } else {
          this.renderer.setAttribute($el, 'src', el.src);
        }
        this.intersect.unsubscribe();
      }
    });
    this.lazyLoad.elInitSource.next({ref: this.elRef, src: this.appLazyLoadSrc, bg: this.lazyBg});
  }
}
