import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CartService} from "../../services/cart.service";

@Component({
  selector: 'app-checkout-result',
  templateUrl: './checkout-result.component.html',
  styleUrls: ['./checkout-result.component.styl']
})
export class CheckoutResultComponent implements OnInit {
  public status: 'cancel' | 'fail' | 'ok'  = 'ok';

  constructor(private route: ActivatedRoute, public cart: CartService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.status = params.status;
      if (this.status === 'ok') {
        this.cart.drop(false);
      }
    });
  }
}
