import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ISetupPassword} from "../types/ISetupPassword";
import {IConfirmEmail} from "../types/IConfirmEmail";
import {IConfirmEmailRes} from "../types/IConfirmEmailRes";
import {ISettings, ISettingsCommissionFee, ISettingsDonationOptions} from "../types/Settings";
import {IOrganisation, IUser, TUserRole} from "../types/User";
import {ICountry} from "../types/ICountry";
import {ISector} from "../types/ISector";
import {Form} from "@angular/forms";
import {IFormDataFile, IInstanceEvent, IProductItem, TDonationTargetType} from "../types/Entities";
import {IMember} from "../types/IMember";
import {IProject} from "../types/IProject";
import {IContentHome, IContentWhats} from "../types/Content";
import {ITransaction} from "../types/ITransaction";

export interface IProjectsReqParams {
  orgId?: string;
  offset?: number;
  limit?: number;
  details?: string;
  id?: string;
}

const defaultProjectsReqParams: IProjectsReqParams = {
  details: 'sectors,country,images,org',
  limit: 1000
};

@Injectable({
  providedIn: 'root'
})
export class TransportService {

  constructor(private http: HttpClient) {
  }

  public getSettings(name: 'content-home' | 'content-whats' | 'commission-fee' | 'donation-options'):
    Observable<IContentHome | IContentWhats | ISettingsDonationOptions | ISettingsCommissionFee> {
    return this.http.get<any>(`/api/settings/${name}`);
  }

  public getUsers(): Observable<HttpResponse<any>> {
    return this.http.get('/api/users', {observe: 'response'});
  }

  public getUser(): Observable<IUser | IOrganisation> {
    return this.http.get('/api/session');
  }

  public patchUser(body: IUser | { organisation: IOrganisation }): Observable<IUser | IOrganisation> {
    return this.http.post<IUser | IOrganisation>('/api/session', body);
  }

  public getUserRecommendProjects(): Observable<IProject[]> {
    return this.http.get<IProject[]>('/api/session/recomendated-projects');
  }

  public uploadDoc(body: FormData) {
    return this.http.post<FormData>('/api/documents', body);
  }

  public uploadFile(body: FormData): Observable<IFormDataFile> {
    return this.http.post<IFormDataFile>('/api/documents', body);
  }

  public getFiles(ownerId): Observable<IFormDataFile[]> {
    return this.http.get<IFormDataFile[]>(`/api/documents/${ownerId}`);
  }

  public signUp(body: IUser | IOrganisation): Observable<any> {
    return this.http.post('/api/sign-up', {_token: false, ...body});
  }

  public signUpGuest(): Observable<{token: string}> {
    return this.http.post<{token: string}>('/api/sign-up/guest', null);
  }

  public signIn(body: IUser): Observable<IUser | IOrganisation> {
    return this.http.post(`api/sign-in`, body);
  }

  public forgetPassword(body: { email: string }): Observable<any> {
    return this.http.post(`api/sign-up/password-recovery`, body);
    // return of(body);
  }

  public setPassword(body: ISetupPassword): Observable<any> {
    return this.http.post(`api/sign-up/setup-password`, body);
  }

  public changePassword(body: {password: string}): Observable<{token: string}> {
    return this.http.post<{token: string}>(`api/session/change-password`, body);
  }

  public confirmEmail(body: IConfirmEmail): Observable<IConfirmEmailRes> {
    return this.http.post<IConfirmEmailRes>(`api/sign-up/confirm-email`, body);
  }

  public getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>('/api/countries');
  }

  public getSectors(params: { targetId?: string, type?: 'organisation' | 'project' } = {}): Observable<ISector[]> {
    const paramsKeys = Object.keys(params);
    return this.http.get<ISector[]>(`/api/sectors${paramsKeys.length ? `?${paramsKeys.map(k => `${k}=${params[k]}`).join('&')}` : ''}`);
  }

  public getMembers(targetId): Observable<(IMember[] | number)[]> {
    return this.http.get<(IMember[] | number)[]>(`/api/members/${targetId}`);
  }

  //
  // public addMember(body: IMember) {
  //   return this.http.post<IMember>('/api/members', body);
  // }

  public addMembers(data: { targetId: string, members: IMember[] }): Observable<IMember[]> {
    return this.http.post<IMember[]>('/api/members', data);
  }

  public deleteMember(id: string): Observable<void> {
    return this.http.delete<void>(`/api/members/${id}`);
  }

  public patchMember(body: IMember): Observable<void> {
    return this.http.post<void>(`/api/members/${body.id}`, {fullName: body.fullName, role: body.role} as IMember);
  }

  public attachSector(body: { type: 'project' | 'organisation', targetId: string, sectorId: string }): Observable<void> {
    return this.http.post<void>(`/api/sectors/attach`, body);
  }

  public detachSector(body: { type: 'project' | 'organisation', targetId: string, sectorId: string }): Observable<void> {
    return this.http.post<void>(`/api/sectors/detach`, body);
  }

  public getProjects(
    params: IProjectsReqParams = {}
  ): Observable<((IProject[] | number)[]) | IProject> {
    const _params: IProjectsReqParams = {...defaultProjectsReqParams, ...params};
    const paramsKeys = Object.keys(_params);

    return this.http.get<(IProject[] | number)[]>(
      `api/projects${paramsKeys.length ?
        `?${paramsKeys.map(k => `${k}=${_params[k]}`).join('&').replace(/\s/gm, '')}` : ''}`
    );
  }

  public getSimilarProjects(id: string, params?: IProjectsReqParams): Observable<(IProject[] | number)[]> {
    const _params: IProjectsReqParams = {...defaultProjectsReqParams, ...params};
    const paramsKeys = Object.keys(_params);

    return this.http.get<(IProject[] | number)[]>(
      `/api/projects/${id}/similar${paramsKeys.length ?
        `?${paramsKeys.map(k => `${k}=${_params[k]}`).join('&').replace(/\s/gm, '')}` : ''}`
    );
  }

  public getOrganisations(
    params: { offset?: number, limit?: number, details?: string, id?: string } = {}
  ): Observable<((IOrganisation[] | number)[]) | IOrganisation> {
    const paramsKeys = Object.keys(params);
    return this.http.get<(IOrganisation[] | number)[]>(
      `api/organisations${paramsKeys.length ?
        `?${paramsKeys.map(k => `${k}=${params[k]}`).join('&').replace(/\s/gm, '')}` : ''}`
    );
  }

  public createProject(targetId: string, body?: IProject): Observable<IProject> {
    return this.http.post(`api/projects?id=${targetId}`, body);
  }

  public deleteProject(id: string, targetId: string): Observable<IProject> {
    return this.http.delete(`api/projects/${id}?id=${targetId}`);
  }

  public patchProject(id: string, body: IProject): Observable<IProject> {
    return this.http.post(`api/projects/${id}`, body);
  }

  public completeProject(id: string, body: IProject): Observable<IProject> {
    return this.http.post(`api/projects/${id}/complete`, body);
  }

  public getDonations(
    params: { offset?: number, limit?: number, details?: string, userId?: string, targetId?: string, targetType?: TDonationTargetType } = {}
  ): Observable<[IProductItem[], number]> {
    const paramsKeys = Object.keys(params);
    return this.http.get<any>(
      `api/donations${paramsKeys.length ?
        `?${paramsKeys.map(k => `${k}=${params[k]}`).join('&').replace(/\s/gm, '')}` : ''}`
    );
  }

  public getRecurringDonations(userId: string): Observable<(IProductItem[] | number)[]> {
    return this.http.get<any>(`api/donations?userId=${userId}&type=recuring&details=no-pagination`);
  }

  public donate(body: IProductItem): Observable<IProductItem> {
    return this.http.post<IProductItem>(`api/donations`, body);
  }

  public deleteDonation(id: string): Observable<void> {
    return this.http.delete<void>(`api/donations/${id}`);
  }

  public dropCart(): Observable<void> {
    return this.http.delete<void>(`api/donations/pending`);
  }

  public pauseRecuringDonations(id: string): Observable<void> {
    return this.http.post<void>(`api/donations/pause`, {donationId: id});
  }

  public renewRecuringDonations(id: string): Observable<void> {
    return this.http.post<void>(`api/donations/reactivate`, {donationId: id});
  }

  public cancelRecuringDonations(id: string): Observable<void> {
    return this.http.post<void>(`api/donations/cancel-subscription`, {donationId: id});
  }

  public getTransactions(
    params: { offset?: number, limit?: number, details?: string, projectId?: string, orgId?: string } = {}
  ): Observable<(ITransaction[] | number)[]> {
    const paramsKeys = Object.keys(params);
    return this.http.get<(ITransaction[] | number)[]>(
      `api/transactions${paramsKeys.length ?
        `?${paramsKeys.map(k => `${k}=${params[k]}`).join('&').replace(/\s/gm, '')}` : ''}`
    );
  }

  public checkout(body: IUser): Observable<{checkoutId: string}> {
    return this.http.post<{checkoutId: string}>(`api/donations/checkout`, body);
  }

  public getEvents(
    params: {offset?: number, limit: number}
  ): Observable<[IInstanceEvent[], number]> {
    return this.http.get<[IInstanceEvent[], number]>(`api/events?offset=${params.offset}&limit=${params.limit}`);
  }

  public markReadedEvent(id): Observable<void> {
    return this.http.post<void>(`api/events/${id}`, null);
  }

  public getFeaturedUsers(): Observable<[IUser[], number]> {
    return this.http.get<[IUser[], number]>('api/users?role=user&details=featured,images');
  }
}
