import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {IProject} from "../../types/IProject";
import {IOrganisation} from "../../types/User";
import {Router} from "@angular/router";

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.styl']
})
export class ProjectItemComponent implements OnInit {
  @Input() data: IProject;
  @Input() org: IOrganisation;
  @Input() support = true;
  @Input() editing = false;
  @Input() status: 'ok' | 'pending' | 'revoked';
  @Input() link = true;

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor(private router: Router, public global: GlobalService) { }

  ngOnInit() {
  }

  onEdit() {
    this.edit.emit(this.data);
  }

  onDelete() {
    this.delete.emit(this.data);
  }

  onClick() {
    // && (this.data.status === 'approved' || this.data.status === 'changed')
    if (this.link) {
      this.router.navigate([`/project/${this.data.id}`]);
    }
  }
}
