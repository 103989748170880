import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperDirective} from "ngx-swiper-wrapper";
import {fromEvent, of} from "rxjs";
import {debounceTime, delay, distinctUntilChanged, filter, map} from "rxjs/operators";
import {IMember} from "../../types/IMember";
import {IUser} from '../../types/User';
import {clone} from 'ramda';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.styl']
})
export class PeopleComponent implements OnInit, AfterViewInit {
  @Input() members: IMember[];
  @Input() users: IUser[];
  @Input() title;
  @Input() theme: 'page' | 'common' = 'common';
  @ViewChild(SwiperDirective, {static: false}) swiper;

  public items: any[];
  public mode: 'slider' | 'scroll' = 'scroll';
  public peopleSlides: Array<any[]> = [];
  public currSliderIndex = 0;
  public slideItemsAmount = 0;
  public slideItemMinWidth = 162;
  public slideItemsMinCount = 4;
  public slideItemsMinGap = 54;

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    this.items = clone(this.members || this.users);
    this.onRisize();
    console.log('people', this.items);
    console.log('people slides', this.peopleSlides);
    console.log('people mode', this.mode);
  }

  ngAfterViewInit() {
  }

  @HostListener('window:resize', ['$event'])
  onRisize() {
    const $parent = this.elRef.nativeElement.parentNode;
    const parentW = $parent.clientWidth;
    // of(parentW).pipe(
    //   map(w => w),
    //   filter(w => w),
    //   debounceTime(10000),
    //   distinctUntilChanged()
    // ).subscribe((w) => {
    this.calcSlider(parentW);
    // });
  }

  calcSlider(parentW: number) {
    const itemsCount = Math.floor((parentW + this.slideItemsMinGap) / (this.slideItemMinWidth + this.slideItemsMinGap));
    if (
      this.items && this.items.length &&
      itemsCount >= this.slideItemsMinCount
    ) {
      this.slideItemsAmount = itemsCount;
      this.compareBySlides(itemsCount);
      this.mode = 'slider';
    } else {
      this.mode = 'scroll';
    }
  }

  compareBySlides(itemsCount) {
    const slidesCount = Math.ceil(this.items.length / itemsCount);
    this.peopleSlides = Array(slidesCount).fill(0).map((item, i) => {
      return this.items.slice(i * itemsCount, (i + 1) * itemsCount);
    });
  }

  onSlideIndexChange(index: number) {
    this.currSliderIndex = index;
  }
}
