import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appToggleClass]'
})
export class ToggleClassDirective implements AfterViewInit, OnDestroy {
  @Input() appToggleClass = '';
  @Input() toggleClassEvent = 'click';
  private listener;
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen(this.elRef.nativeElement, this.toggleClassEvent, (event) => {
      // event.stopPropagation();
      this.elRef.nativeElement.classList.toggle(this.appToggleClass);
    });
  }

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener();
    }
  }
}
