import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class FsService {

  constructor(private sanitizer: DomSanitizer) { }

  base64UrlRef(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        reject();
      }
    });
  }

  blobUrlRef(file: File): string {
    return window.URL.createObjectURL(file);
    // return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, window.URL.createObjectURL(file));
    // window.URL.revokeObjectURL(path);
  }
}
