import {Component, Input, OnInit} from '@angular/core';
import {TDonationType} from "../../../types/Entities";
import {CartService} from "../../../services/cart.service";

@Component({
  selector: 'app-cart-table-row-total',
  templateUrl: './cart-table-row-total.component.html',
  styleUrls: ['./cart-table-row-total.component.styl']
})
export class CartTableRowTotalComponent implements OnInit {
  @Input() fee = 0;
  @Input() total: number;
  @Input() totalTitle: string;
  @Input() type: TDonationType;

  constructor(public cart: CartService) { }

  ngOnInit() {
  }

}
