import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {links} from "../../static_data/nav";
import {IFormDataFile, INavLink} from "../../types/Entities";
import {AuthService} from "../../services/auth.service";
import {GlobalService} from "../../services/global.service";
import {combineLatest, Subscription} from "rxjs";
import {IUser} from "../../types/User";
import {TransportService} from "../../services/transport.service";

export interface IHeaderInputs {
  logo?: boolean;
  theme?: string;
  pad?: string;
  mb?: string;
  pos?: string;
  nav?: boolean;
  user?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public navLinks: INavLink[] = links;
  public defaultInputs = {theme: '1', mb: '2', nav: true, user: true};
  public showPopover = false;
  public showPopoverClicked = false;
  public showPopoverEvent: 'click' | 'mouseenter' | 'mouseleave';
  public subscribtions: Subscription[] = [];
  public userInit = false;
  public isLoggedIn = false;
  public user: IUser;
  public avatar: IFormDataFile;
  @Input() inputs: IHeaderInputs = this.defaultInputs;

  constructor(public authService: AuthService, public global: GlobalService, private api: TransportService) {
    this.setUser();
  }

  ngOnInit() {
    this.inputs = {...this.defaultInputs, ...this.inputs};
    if (this.inputs.user) {
      this.subscribtions.push(
        this.authService.user$.subscribe((user) => {
          this.setUser();
        }),
        this.authService.userAvatar$.subscribe((data) => {
          this.avatar = data;
        })
      );
    }
  }

  setUser() {
    this.userInit = false;
    combineLatest(
      this.authService.isLoggedIn(),
      this.authService.getUser(),
      this.authService.getUserAvatar()
    ).toPromise().then(
      ([isLoggedIn, user, avatar]) => {
        this.isLoggedIn = isLoggedIn;
        this.user = user;
        this.userInit = true;
        this.avatar = avatar;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribtions.map(s => s.unsubscribe());
  }

  togglePopover(e, type: 'click' | 'mouseenter' | 'mouseleave') {
    if (!this.global.isDesktop) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    if (type === 'click') {
      this.showPopoverClicked = !this.showPopoverClicked;
    }
    this.showPopoverEvent = type;
    this.showPopover = (this.showPopoverClicked && !this.global.isDesktop) || this.showPopoverEvent === 'mouseenter';
  }
}
