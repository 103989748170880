import {Component, Input, OnInit} from '@angular/core';
import {LangService} from "../../services/lang.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.styl']
})
export class LangSelectComponent implements OnInit {
  @Input() theme = '1';
  public selected: string;

  constructor(
    private translate: TranslateService,
    public langService: LangService,
    private fb: FormBuilder,
    private storage: StorageService) { }

  ngOnInit(): void {
    this.selected = this.storage.getItem('language') || this.translate.getDefaultLang();
  }

  switchLang(ev) {
    this.langService.switchLanguage(ev.val);
  }
}
