import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Directive({
  selector: '[appAutoFontSize]'
})
export class AutoFontSizeDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() container: HTMLElement;
  @Input() maxContainerHeight: number;
  @Input() init$: Observable<void>;
  private subscribtions: Subscription[];

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.init$) {
      this.subscribtions = [
        this.init$.subscribe(() => {
          this.run();
        })
      ];
    }
  }

  ngAfterViewInit(): void {
    if (!this.init$) {
      this.run();
    }
  }

  ngOnDestroy(): void {
    if (this.subscribtions) {
      this.subscribtions.map(s => s.unsubscribe());
    }
  }

  run() {
    const $el: HTMLElement = this.elRef.nativeElement;
    const $container: HTMLElement = this.container;
    const elStyle: string = window.getComputedStyle($el, null).getPropertyValue('font-size');
    let currentFz: number = parseFloat(elStyle);
    if ($container.clientHeight > this.maxContainerHeight) {
      while ($container.clientHeight > this.maxContainerHeight) {
        currentFz--;
        $el.style.fontSize = `${currentFz}px`;
      }
    }
  }
}
