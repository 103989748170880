import { Pipe, PipeTransform } from '@angular/core';
import {IProject, TProjectCategory} from "../../types/IProject";

@Pipe({
  name: 'projectCategory',
})
export class ProjectCategoryPipe implements PipeTransform {

  transform(value: IProject[], category: TProjectCategory): IProject[] {
    return value.filter(p => p.category ? p.category === category : true);
  }

}
