import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CartService} from "../../services/cart.service";
import {Subscription} from "rxjs";
import {transition, trigger, useAnimation} from "@angular/animations";
import {fadeAnimationHide, fadeAnimationShow} from "../../animations";

@Component({
  selector: 'app-cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.styl'],
  animations: [
    trigger('showAnimationTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, {params: {timings: '400ms ease-in-out'}})
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, {params: {timings: '400ms ease-in-out'}})
      ]),
    ])
  ]
})
export class CartPanelComponent implements OnInit, OnDestroy {
  public rect: ClientRect;
  private subscribtions: Subscription[] = [];

  constructor(public cart: CartService) { }

  ngOnInit() {
    this.subscribtions.push(
      this.cart.addRemoveItems$.subscribe((data) => {
        this.rect = (data || {}).rect;
      })
    );
  }

  ngOnDestroy(): void {
  }
}
