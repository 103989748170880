import { Injectable } from '@angular/core';
import {DeviceInfo} from "ngx-device-detector";
import {ReplaySubject} from "rxjs";
import {clone} from 'ramda';
import {StorageService} from "./storage.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public settings = {};
  public $settings = new ReplaySubject(1);
  public deviceInfo: DeviceInfo;
  public deviceType: string;
  public isDesktop: boolean;
  public mobileWidth = 991;
  public defaultLang = 'en';

  constructor(private storage: StorageService, private translate: TranslateService) { }

  public initLang(t?) {
    const lang = this.storage.getItem('language') || this.defaultLang;
    (t || this.translate).setDefaultLang(this.defaultLang);
    (t || this.translate).use(lang);
  }

  public setDevice(info: DeviceInfo, isDesktop: boolean) {
    this.deviceInfo = info;
    this.deviceType = isDesktop ? 'desktop' : 'mobile';
    this.isDesktop = isDesktop;
  }

  public isMobileWidth(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  public toggleBodyOverflow(show: boolean) {
    const value = show ? '' : 'hidden';
    document.querySelector('html').style.overflow = value;
    document.body.style.overflow = value;
  }
}
