import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-ref',
  templateUrl: './svg-ref.component.html',
  styleUrls: ['./svg-ref.component.styl']
})
export class SvgRefComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() viewBox: string;
  @Input() uuid: string;

  constructor() { }

  ngOnInit() {
  }

  getViewBox() {
    return `${this.viewBox || (`0 0 ${this.width} ${this.height}`)}`;
  }
}
