import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TabsComponent} from "../../components/tabs/tabs.component";
import {TabsTabComponent} from "../../components/tabs/tabs-tab/tabs-tab.component";
import {ScrollModule} from "../scroll/scroll.module";
import {TabsTabContentTemplateDirective, TabsTabIconTemplateDirective} from "./tabs-templates.directive";

@NgModule({
  declarations: [TabsComponent, TabsTabComponent, TabsTabIconTemplateDirective, TabsTabContentTemplateDirective],
  exports: [TabsComponent, TabsTabComponent, TabsTabIconTemplateDirective, TabsTabContentTemplateDirective],
  imports: [
    CommonModule,
    ScrollModule
  ]
})
export class TabsModule { }
