import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {animate, state, style, transition, trigger, useAnimation} from "@angular/animations";
import {fadeAnimationHide, fadeAnimationShow} from "../../../animations";
import {GlobalService} from "../../../services/global.service";

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.styl'],
  animations: [
    trigger('showAnimationTrigger', [
      transition(':enter', [
        useAnimation(fadeAnimationShow, {params: {timings: '800ms ease-in-out'}})
      ]),
      transition(':leave', [
        useAnimation(fadeAnimationHide, {params: {timings: '800ms ease-in-out'}})
      ]),
    ])
  ]
})
export class AuthPopupComponent implements OnInit, OnDestroy {
  constructor(public authService: AuthService, public global: GlobalService) {
  }
  ngOnInit() {
    this.authService.showHidePopup$.subscribe((show) => {
      this.global.toggleBodyOverflow(!show);
    });
  }

  ngOnDestroy() {
  }
}
