import {Component, Input, OnInit} from '@angular/core';
import {Observable, of, Subject, Subscription} from "rxjs";
import {IMember} from "../../types/IMember";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {TransportService} from "../../services/transport.service";
import {clone, difference} from 'ramda';
import {FormService} from "../../services/form.service";
import {IMemberValueChange} from "../project-members/project-members.component";
import {IFormDataFile, TFileTypes} from "../../types/Entities";
import {LoggerService} from "../../services/logger.service";

@Component({
  selector: 'app-members-wrapper',
  templateUrl: './members-wrapper.component.html',
  styleUrls: ['./members-wrapper.component.styl']
})
export class MembersWrapperComponent implements OnInit {
  @Input() targetId: string;
  @Input() cancel$: Observable<void>;
  @Input() save$: Observable<void | string>;
  @Input() disabled = false;
  @Input() syncNewMembers = true;

  public addMemberSource = new Subject<IMember>();
  public removeMemberSource = new Subject<IMember>();
  public setMembersSource = new Subject<IMember[]>();
  public patchMemberSource = new Subject<IMember>();
  public addMember$ = this.addMemberSource.asObservable();
  public removeMember$ = this.removeMemberSource.asObservable();
  public setMembers$ = this.setMembersSource.asObservable();
  public patchMember$ = this.patchMemberSource.asObservable();

  private subscriptions: Subscription[] = [];
  private files: IFormDataFile[] = [];

  public members = [];
  public formSubject = {
    _members: []
  };

  constructor(private api: TransportService, private logger: LoggerService, public formService: FormService) {
  }

  ngOnInit() {
    if (this.targetId) {
      this.api.getMembers(this.targetId).toPromise().then((members) => {
        // this.members = members;
        // this.formSubject._members = members && members.length ? clone(members) : [];
        this.members = members[0] as IMember[];
        // this.members.map(m => this.api.deleteMember(m.id).toPromise());
        this.formSubject._members = clone(this.members);
      });
    }

    if (this.save$) {
      this.subscriptions.push(
        this.save$.subscribe((id) => {
          if (id) {
            this.targetId = id;
          }
          this.onSave();
        })
      );
    }
  }

  uploadFile(file: File, type: TFileTypes, ownerId?: string) {
    this.files = this.formService.makeAndChangeFilesArr({file, ownerId: ownerId || this.targetId, type}, this.files);
    // this.logger.l('uploadFile', this.files);
  }

  addMember(member: IMember) {
    this.formSubject._members.push(member);
    this.addMemberSource.next(member);
  }

  patchMember(member: IMember, key: keyof IMember, value) {
    member[key] = value;
    this.patchMemberSource.next(member);
  }

  deleteMember(index: number) {
    this.formSubject._members.splice(index, 1);
  }

  syncNewMember(member: IMember, onAdded?: (m: IMember) => void): Observable<IMember> {
    member.targetId = this.targetId;
    return this.api.addMembers({targetId: this.targetId, members: [member]})
      .pipe(
        mergeMap((members: IMember[]) => {
          const m = members[0];
          if (onAdded) {
            onAdded(m);
          }
          if (member.image && member.image.file) {
            const image = {...member.image, ownerId: m.id};
            return this.api.uploadFile(this.formService.makeFormDataFile(image))
              .pipe(
                map(img => ({...m, image: img})),
                catchError((err) => of(m))
              );
          } else {
            return of(m);
          }
        }),
        catchError((err) => of(null))
      )
      .pipe(
        tap((m: IMember) => {
          if (m.image) {
            this.patchMember(m, 'image', m.image);
          }
          this.logger.l('onAddMember', m);
        })
      );
      // .subscribe(
      //   (m: IMember) => {
      //     if (m.image) {
      //       this.patchMember(m, 'image', m.image);
      //     }
      //     this.logger.l('onAddMember', m);
      //   },
      //   (err) => {
      //   }
      // );
  }

  onAddMember(member: IMember) {
    if (this.syncNewMembers) {
      this.syncNewMember(member, (m) => {
        this.addMember(m);
      }).toPromise();
      //   .subscribe(
      //   (m: IMember) => {
      //     if (m.image) {
      //       this.patchMember(m, 'image', m.image);
      //     }
      //     this.logger.l('onAddMember', m);
      //   }
      // );
    } else {
      this.addMember(member);
    }
  }

  onMemberChangeValue(data: IMemberValueChange) {
    // const m = this.formSubject._members.find((_m) => _m.id === member.id);
    this.formSubject._members[data.index][data.key] = data.value;
  }

  onRemoveMember(index: number) {
    this.deleteMember(index);
  }

  onMemberImageChange(image: IFormDataFile) {
    this.uploadFile(image.file, 'member-image', image.ownerId);
    // if (image.ownerId) {
    //   this.patchMember(this.formSubject._members.find(m => m.id === image.ownerId), 'image', image);
    // }
  }

  onSave() {
    // if (!this.syncNewMembers) {
    //   this.formSubject._members.map((m: IMember) => {
    //     this.syncNewMember(m).toPromise();
    //   });
    // } else {
    //
    // }
    const addedMembers = this.formSubject._members.filter((m) => !this.members.some(_m => _m.id === m.id));
    const changedMembers = difference(this.formSubject._members, this.members);
    const removedMembers = this.members.filter((m) => !this.formSubject._members.some(_m => _m.id === m.id));

    changedMembers.map((m: IMember) => {
      if (m && m.id) {
        this.api.patchMember(m).toPromise();
      }
    });
    removedMembers.map((m: IMember) => {
      this.api.deleteMember(m.id).toPromise();
    });
    addedMembers.map((m: IMember) => {
      this.syncNewMember(m).toPromise();
    });
    this.formService.compareFormDataFilesArr(this.files).map(data => this.api.uploadFile(data).toPromise());
    this.members = this.formSubject._members;
    this.logger.l('added members', addedMembers);
    this.logger.l('removed members', removedMembers);
    this.logger.l('changed members', changedMembers);
  }

  onCancel() {
    const newMembers = this.formSubject._members.filter((m) => !this.members.some((_m) => _m.id === m.id));
    newMembers.map((m: IMember) => {
      this.api.deleteMember(m.id).toPromise();
    });
    this.setMembersSource.next(this.members);
  }
}
