import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IUser} from "../../../types/User";
import {FormBuilder, Validators} from "@angular/forms";
import {FormService} from "../../../services/form.service";

@Component({
  selector: 'app-auth-forget-reset',
  templateUrl: './auth-forget-reset.component.html',
  styleUrls: ['./auth-forget-reset.component.styl']
})
export class AuthForgetResetComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<IUser>();
  public form = this.fb.group({
    password: ['', [Validators.required]],
    password_repeat: ['', [Validators.required]]
  });
  constructor(
    public fb: FormBuilder,
    public formService: FormService) { }

  ngOnInit() {
  }

  onSubmit() {
    const body = Object.assign({}, this.form.value);
    // this.formSubmit.emit(body);
    if (this.form.valid) {
      this.formSubmit.emit(body);
    } else {
      // this.errors = this.formService.getErrors(this.form);
      // this.error = this.errors[0];
      this.formService.markFormGroupTouched(this.form);
    }
  }

}
