import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuillConfig, QuillFormat} from "ngx-quill";


export interface IQuillCreated {
  text: string;
  html: string;
}

@Component({
  selector: 'app-values-text-area',
  templateUrl: './values-text-area.component.html',
  styleUrls: ['./values-text-area.component.styl']
})
export class ValuesTextAreaComponent implements OnInit {
  @Input() disabled = false;
  @Output() contentChange = new EventEmitter<any>();
  @Output() textChange = new EventEmitter<string>();
  @Output() created = new EventEmitter<IQuillCreated>();
  @Input() model = '';
  public editor: any;
  public text: string;

  public quillConfig: QuillConfig = {
    modules: {
      toolbar: null
    }
  };
  constructor() { }

  ngOnInit() {
    // this.quill.text = this.model;
  }

  onContentChange(e) {
    // this.editor = e;
    this.text = e.text;
    this.contentChange.emit(e);
    this.textChange.emit(e.text);
  }

  onCreated(editor) {
    this.editor = editor;
    this.created.emit({
      text: editor.getText(),
      html: editor.root.innerHTML
    });
  }
}
