import {FormControl, FormGroup} from '@angular/forms';
import {ValidationService} from "../services/validation.service";

// custom validator to check that two fields match
export function validateEmailFactory() {
  return (formControl: FormControl) => {
    // return null if controls haven't initialised yet
    if (!formControl.value) {
      return null;
    }

    // return null if another validator has already found an error on the matchingControl
    if (formControl.errors && !formControl.errors.emailValidator) {
      return null;
    }

    // set error on matchingControl if validation fails
    return ValidationService.emailValidator(formControl);
    // if () {
    //   // formControl.setErrors(null);
    //   return null;
    // } else {
    //   // formControl.setErrors({phoneValidator: true});
    //   return {phoneValidator: true};
    // }
  };
}
