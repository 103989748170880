import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {Observable, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      mergeMap(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate([''], {queryParams: {returnUrl: state.url}});
        }
        return of(isLoggedIn);
      })
    );
    // if (this.authService.isLoggedIn) {
    //   return of(true);
    // } else {
    //   if (this.authService.isLoggedInGuest) {
    //     return this.authService.user$.pipe(mergeMap((user) => {
    //       if (this.authService.isLoggedIn) {
    //         return of(true);
    //       } else {
    //         this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    //         return of(false);
    //       }
    //     }));
    //   } else {
    //     this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    //     return of(false);
    //   }
    // this.router.navigate([''], { queryParams: { returnUrl: state.url } });
  }
}
