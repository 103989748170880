import {AfterViewInit, Component, ContentChildren, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {combineLatest, Observable, of, Subject, Subscription} from "rxjs";
import {delay, map} from "rxjs/operators";
import {IInsert, NavProjectsComponent} from "../../components/nav-projects/nav-projects.component";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {IMember} from "../../types/IMember";
import {TransportService} from "../../services/transport.service";
import {IContentHome, IContentWhats} from "../../types/Content";
import {CartService} from "../../services/cart.service";
import {IUser} from '../../types/User';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class IndexComponent implements OnInit, AfterViewInit {
  @ViewChildren(NavProjectsComponent) projects: QueryList<NavProjectsComponent>;

  public peopleInsert: IUser[];
  public projectsInserts: IInsert[];
  public render: any = null;
  public contentHome: IContentHome;
  // private subscriptions: Subscription[] = [];
  private authShowHideSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              public authService: AuthService,
              public api: TransportService,
              public cart: CartService) {
  }

  ngOnInit(): void {
    this.api.getSettings('content-home').subscribe((settings: IContentHome) => this.contentHome = settings);
    combineLatest(this.route.params, this.route.queryParams).pipe(
      map(([params, query]) => ({...params, ...query}))
    ).subscribe((params) => {
      this.render = !params.confirmWay;
      this.init();

      if (params.confirmWay) {
        switch (params.confirmWay) {
          case 'email':
            this.authService.confirmEmail({token: params.token}).subscribe(() => {
              this.authService.setState({tab: 'sign_up', step: 'password'});
              this.authService.openPopup();
            }, () => {
              this.render = true;
            });
            break;
          case 'password':
            this.authService.confirmEmail({token: params.token}).subscribe(() => {
              this.authService.setState({tab: 'forgot', step: 'password'});
              this.authService.openPopup();
            }, () => {
              this.render = true;
            });
            break;
        }
        this.authShowHideSubscription = this.authService.showHidePopup$.subscribe((show) => {
          if (!show && this.authShowHideSubscription) {
            this.render = true;
            this.authShowHideSubscription.unsubscribe();
          }
        });
      }
    });

    // this.cart.recurringDonations$.subscribe();
  }

  ngAfterViewInit(): void {
  }

  init() {
    // this.api.getSettings('content-whats').subscribe(settings => {
    //   this.projectsInserts = [{type: 'members', indexes: [6], data: this.peopleInsert, show_independently: true}];
    // });
    this.api.getFeaturedUsers().subscribe(data => {
      if (data && data[0] && data[0].length) {
        this.projectsInserts = [{type: 'users', indexes: [6], data: data[0], show_independently: true}];
      }
    });
    // Promise.resolve(null).then(() => {
    //   this.projects.toArray().map(pr => pr.disableInfiniteScroll$.next(true));
    //   this.projects.first.disableInfiniteScroll$.next(false);
    // });
  }

  onNavProjectsTabShowHide(ev) {
    if (this.projects) {
      const project = this.projects.toArray().find(pr => pr.uuid === ev.data.uuid);
      project.disableInfiniteScroll$.next(!ev.value);
    }
  }
}
