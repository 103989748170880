import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-project-urgent-item',
  templateUrl: './project-urgent-item.component.html',
  styleUrls: ['./project-urgent-item.component.styl']
})
export class ProjectUrgentItemComponent implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
