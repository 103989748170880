import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.styl']
})
export class ProjectsListComponent implements OnInit {
  @Input() items;
  constructor() { }

  ngOnInit() {
  }

}
