import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ILink, IMultilangString, IProductItem, TDonationTargetType, TDonationType} from "../../types/Entities";
import {Observable, of, Subject, Subscription} from "rxjs/index";
import {IItem} from "../scroll/scroll.component";
import {ISettingsDonationOptions} from "../../types/Settings";
import {CartService} from "../../services/cart.service";
import {IOrganisation} from "../../types/User";
import {IProject} from "../../types/IProject";
import {GlobalService} from "../../services/global.service";
import {debounceTime, delay, distinctUntilChanged, switchMap} from "rxjs/operators";

export interface IType {
  name: IMultilangString;
  value: TDonationType;
}


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.styl']
})
export class SupportComponent implements OnInit, OnDestroy {
  @Input() theme: 'fund' | 'project' | 'page' = 'fund';
  @Input() targetType: TDonationTargetType;
  @Input() donationType: keyof ISettingsDonationOptions;
  @Input() title;
  @Input() subTitle;
  @Input() custom: boolean;
  @Input() customValue: number;
  @Input() org: IOrganisation;
  @Input() project: IProject;
  @Input() links: ILink[];
  @Input() link: string;
  @Input() currentType: TDonationType;
  @Output() typeChange: EventEmitter<TDonationType> = new EventEmitter();
  @Output() priceChange: EventEmitter<number> = new EventEmitter();
  public types: Array<IType> = [
    {
      value: 'recuring',
      name: {
        en: 'Monthly'
      }
    },
    {
      value: 'single',
      name: {
        en: 'Once'
      }
    }
  ];
  public prices: number[];
  private subscriptions: Subscription[] = [];
  public currentPrice: number;
  private currentProductItem: IProductItem;
  private custom$: Subject<number>;

  constructor(public cartService: CartService, public global: GlobalService) {
  }

  ngOnInit() {
    this.init();
    if (this.custom) {
      this.custom$ = new Subject();
      this.subscriptions.push(
        this.custom$
          .pipe(
            debounceTime(400),
            // distinctUntilChanged(),
          )
          .subscribe(
            (value) => {
              this.changePrice(value, false);
            }
          )
      );
    }
    this.subscriptions.push(
      this.cartService.drop$.subscribe(() => {
        this.init();
      })
    );
    if (this.custom) {
      this.subscriptions.push(
        this.cartService.donationsOptions$.subscribe(prices => {
          this.init();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  init() {
    if (this.cartService.donationsOptions && this.cartService.donationsOptions[this.donationType]) {
      this.prices = this.cartService.donationsOptions[this.donationType].sort((a, b) => a - b);
    }
    const productItem = this.cartService.getProductItem(this.project ? this.project.id : this.org.id);
    if (productItem) {
      this.currentPrice = productItem.amount;
      this.currentProductItem = productItem;
      this.currentType = productItem.type;
      if (this.prices && this.currentPrice > this.prices[this.prices.length - 1]) {
        this.customValue = this.currentPrice;
      }
    } else {
      this.currentPrice = 0;
      this.currentProductItem = null;
      this.currentType = this.targetType === 'ORG' ? 'recuring' : 'single';
    }
  }

  changeType(type: TDonationType) {
    if (this.currentType === type) {
      return;
    }
    if (this.currentProductItem) {
      this.cartService.changeItemType(this.currentProductItem, type, this.currentPrice);
    }
    this.currentType = type;
    this.typeChange.emit(type);
  }

  changePrice(price: number, opt = true, event?) {
    const rect = event && event.target ? event.target.getBoundingClientRect() : null;
    if (opt) {
      this.customValue = null;
    }
    const productItem: IProductItem = {
      id: this.currentProductItem ? this.currentProductItem.id : undefined,
      targetId: this.project ? this.project.id : this.org.id,
      targetType: this.targetType,
      organisation: this.org,
      project: this.project,
      amount: price,
      type: this.currentType
    };
    if (this.currentPrice === price && opt) {
      this.cartService.removeItem(productItem, false, rect);
      this.currentPrice = undefined;
      this.currentProductItem = null;
      return;
    } else {
      if (this.currentProductItem) {
        this.cartService.removeItem(this.currentProductItem, false, rect);
      }
      this.cartService.addItem(productItem, rect);
    }
    this.currentProductItem = productItem;
    this.currentPrice = price;
    this.priceChange.emit(price);
  }

  onCustomChange(value) {
    this.custom$.next(value);
  }
}
