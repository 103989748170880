import { Pipe, PipeTransform } from '@angular/core';
import {IProject, TProjectCategory} from "../../types/IProject";
import {TInstanceStatus} from "../../types/Entities";

@Pipe({
  name: 'projectStatus'
})
export class ProjectStatusPipe implements PipeTransform {

  transform(value: IProject[], status: TInstanceStatus[]): IProject[] {
    return value.filter(p => status.some(s => {
      return p.status ? p.status === s : true;
    }));
  }

}
