import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "./services/storage.service";
import {TransportService} from "./services/transport.service";
import {AuthService} from "./services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalService} from "./services/global.service";
import {LoggerService} from "./services/logger.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {CartService, ICartPanelView} from "./services/cart.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  public showCartPanel = true;
  public cartPanelView: ICartPanelView;
  public changeViewTimeOut: any;
  public wrapPb = 0;

  constructor(public global: GlobalService,
              public authService: AuthService,
              private translate: TranslateService,
              private storage: StorageService,
              private api: TransportService,
              private route: ActivatedRoute,
              private logger: LoggerService,
              private device: DeviceDetectorService,
              public cart: CartService,
              private router: Router,
              private ref: ChangeDetectorRef,
  ) {
    const deviceInfo = this.device.getDeviceInfo();
    const isDesktop = this.device.isDesktop() && !deviceInfo.userAgent.match(/Macintosh.*Mac OS X/);
    this.global.initLang();
    this.global.setDevice(deviceInfo, isDesktop);
    logger.l('deviceInfo', deviceInfo, 'isDesktop', isDesktop);
  }

  ngOnInit(): void {
    this.authService.signIn$.subscribe(() => {
      this.cart.reSync();
    });
    this.cart.cartPanelViewChange$.subscribe((data) => {
      clearTimeout(this.changeViewTimeOut);
      this.changeViewTimeOut = setTimeout(() => {
        this.cartPanelView = data;
        this.calcWrap();
        this.ref.markForCheck();
      }, 200);
    });
    console.log('env production', environment.production);
  }

  onRouterActivate() {
    switch (this.router.url.replace(/\/?\?.*/, '')) {
      case '/checkout':
        this.showCartPanel = false;
        break;
      default:
        this.showCartPanel = true;
        break;
    }
    window.scrollTo(0, 0);
  }

  calcWrap() {
    this.wrapPb = this.cartPanelView && this.cartPanelView.rect ?
      (this.cartPanelView.rect.height + (window.innerHeight - this.cartPanelView.rect.bottom)) : 0;
  }
}
