import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISector} from "../../types/ISector";

@Component({
  selector: 'app-sectors-input',
  templateUrl: './sectors-input.component.html',
  styleUrls: ['./sectors-input.component.styl']
})
export class SectorsInputComponent implements OnInit {
  @Input() customInputSearch: (term: string, item: ISector) => boolean;
  @Input() sectors: ISector[];
  @Input() selectedSectors: any[] = [];
  @Input() disabled = false;
  @Output() sectorsChange = new EventEmitter<ISector[]>();

  constructor() { }

  ngOnInit() {
    this.customInputSearch = this.customInputSearch || this.defaultInputSearch;
  }

  onChange(e: ISector[]) {
    this.sectorsChange.emit(e);
  }

  defaultInputSearch(term: string, item: any) {
    const normalizeString = (str) => {
      return str.replace(/\s|\W/g, '').toLowerCase();
    };
    return normalizeString(item.name).match(normalizeString(term));
  }
}

