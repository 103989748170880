import { Pipe, PipeTransform } from '@angular/core';
import {ISector} from "../../types/ISector";

@Pipe({
  name: 'sectorsFilter'
})
export class SectorsFilterPipe implements PipeTransform {

  transform(sectors: ISector[], key: keyof ISector, value): ISector[] {
    return sectors.filter(s => s[key] === value);
  }
}
