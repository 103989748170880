import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {delay, map} from "rxjs/operators";
import {IProject} from "../../types/IProject";
import {IOrganisation} from "../../types/User";
import {TransportService} from "../../services/transport.service";
import {LoggerService} from "../../services/logger.service";
import {ISector} from "../../types/ISector";
import {clone} from 'ramda';
import {CartService} from "../../services/cart.service";
import {IProductItem} from "../../types/Entities";
import {AuthService} from "../../services/auth.service";

export interface IInsert {
  type: string;
  indexes: Array<number>;
  data: any;
  is_insert?: boolean;
  show_independently?: boolean;
  multiple?: boolean;
}

@Component({
  selector: 'app-nav-projects',
  templateUrl: './nav-projects.component.html',
  styleUrls: ['./nav-projects.component.styl']
})
export class NavProjectsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: 'organisation' | 'project';
  @Input() uuid: string;
  @Input() inserts: Array<IInsert>;
  @Input() donations: Array<IProductItem>;
  @Input() update$: Observable<void>;
  public items: Array<IOrganisation | IProject>;
  public _items: Array<IOrganisation | IProject | IInsert>;
  public _itemsInitial: Array<IOrganisation | IProject | IInsert>;
  public funds: IOrganisation[];
  public projects: IProject[];
  public sectors: ISector[] = [];
  public _sectors: string[] = [];
  public pageLimit = 20;
  public offset = 0;
  public dataLength = 0;
  public showLoader = false;
  public disableInfiniteScroll = true;
  public disableInfiniteScroll$ = new BehaviorSubject<boolean>(true);
  private subscriptions: Subscription[] = [];

  constructor(private api: TransportService, private logger: LoggerService, public auth: AuthService) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.api.getSectors().subscribe((sectors) => {
      this.sectors = sectors;
    });
    this.getItems().subscribe((data) => {
      this.updateItems(data);
      this.showLoader = false;
    });
    this.subscriptions.push(
      this.auth.auth$.subscribe(() => {
        const pageLimit = this.pageLimit;
        const offset = this.offset;
        this.pageLimit = (this.offset || 1) * this.items.length;
        this.offset = 0;
        this.items = [];
        this._items = [];
        this._itemsInitial = [];
        this.getItems().subscribe(data => {
          this.pageLimit = pageLimit;
          this.offset = offset;
          this.updateItems(data);
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inserts && changes.inserts.currentValue && changes.inserts.currentValue.length) {
      this.inserts = changes.inserts.currentValue;
      const newCompared = this.compareItems(this.items);
      this._itemsInitial = newCompared;
      this._items = newCompared.filter(this.isPassForSectors.bind(this));
      this.logger.l('changes inserts', changes.inserts.currentValue);
      this.logger.l('inserts', this.inserts);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  markInserts(inserts: IInsert[]): IInsert[] {
    if (inserts && inserts.length) {
      return inserts.map(ins => {
        ins.is_insert = true;
        return ins;
      });
    } else {
      return null;
    }
  }

  updateItems(data = [null, undefined], currLength = 1) {
    const items = data[0] || this.items;
    const count = data[1] || this.dataLength;
    if (items && items.length) {
      const newCompared = this.compareItems(items, currLength);
      this._itemsInitial = (this._itemsInitial || []).concat(newCompared);
      this._items = (this._items || []).concat(newCompared.filter(this.isPassForSectors.bind(this)));
      this.items = (this.items || []).concat(items);
    }
    this.dataLength = count;
    this.disableInfiniteScroll = this.items.length === this.dataLength;
  }

  compareItems(items: any[], currLength = 1): Array<IInsert | IProject | IOrganisation> {
    let output: Array<IOrganisation | IProject | IInsert> = [];
    if (items && items.length) {
      items.map((item, i) => {
        const inserts: IInsert[] = this.markInserts(this.getInserts(i + (currLength - 1), items.length));
        if (
          inserts &&
          !inserts.some(ins => output.some(_item => ins === _item)) &&
          !inserts.some(ins => this._itemsInitial.some(_item => ins === _item)
          )
        ) {
          if (i + 1 === items.length) {
            output.push(item);
            output = output.concat(inserts);
          } else {
            output = output.concat(inserts);
            output.push(item);
          }
        } else {
          output.push(item);
        }
      });
    }
    this.logger.l('items with inserts', output);
    return output;
  }

  onSectorsChange(ids: string[]) {
    this._sectors = [...ids];
    this._items = ids.length ? this._itemsInitial.filter(this.isPassForSectors.bind(this)) : this._itemsInitial;
    this.logger.l('active sectors', ids);
    // this.logger.l('initial items', this._itemsInitial);
    this.logger.l('filtered sectors', this._items);
    // this.activeTag = tag;
  }

  getItems(): Observable<any> {
    if (this.type === 'organisation') {
      return this.api.getOrganisations({
        limit: this.pageLimit,
        offset: this.offset,
        details: 'sectors,images,country'
      });
    } else {
      return this.api.getProjects({limit: this.pageLimit, offset: this.offset, details: 'sectors,images,org'});
    }
  }

  infiniteScroll() {
    this.showLoader = true;
    this.offset += this.pageLimit;
    this.disableInfiniteScroll = true;
    this.getItems().subscribe((data) => {
      const items = data[0] as any[];
      const count = data[1];
      this.updateItems(data, items.length + this.items.length);
      // if (items && items.length) {
      //   const newCompared = this.compareItems(items, items.length + this.items.length);
      //   this.items = [...this.items, items];
      //   this._itemsInitial = this._itemsInitial.concat(newCompared);
      //   this._items = this._items.concat(newCompared.filter(this.isPassForSectors.bind(this)));
      // }
      this.showLoader = false;
    });
  }

  isPassForSectors(item: any): boolean {
    if (this._sectors.length && !item.is_insert) {
      return item.sectors && item.sectors.length ? item.sectors.some(s => {
        return this._sectors.some(id => id === s.id);
      }) : false;
    } else {
      return true;
    }
  }

  getInserts(i: number, currLength = 1): IInsert[] {
    if (this.inserts && this.inserts.length) {
      return this.inserts.filter((ins) => {
        return ins.indexes.some((index) => {
          return index === i ||
            (
              ins.show_independently && i === currLength - 1
            );
        });
      });
    } else {
      return null;
    }
  }
}
