import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormService} from "../../../services/form.service";
import {ValidationService} from "../../../services/validation.service";
import {IUser} from "../../../types/User";

@Component({
  selector: 'app-auth-sign-up',
  templateUrl: './auth-sign-up.component.html',
  styleUrls: ['./auth-sign-up.component.styl']
})
export class AuthSignUpComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<IUser>();
  //ValidationService.phoneValidator
  public form: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', [Validators.required]],
    role: ['user', [Validators.required]],
    agreement: [false, [Validators.required]],
  });
  public isDirty = false;
  public errors;
  public error;
  constructor(
    public fb: FormBuilder,
    public formService: FormService) { }

  ngOnInit() {
  }

  onRoleChange(e) {
    if (this.form.value.role === 'organisation') {
      this.form
        .addControl(
          'orgName',
          new FormControl('', [Validators.required])
        );
      this.form.removeControl('firstName');
      this.form.removeControl('lastName');
    } else {
      this.form.removeControl('orgName');
      this.form
        .addControl(
          'firstName',
          new FormControl('', [Validators.required])
        );
      this.form
        .addControl(
          'lastName',
          new FormControl('')
        );
    }
  }

  onSubmit() {
    const body = Object.assign({}, this.form.value);
    this.isDirty = true;
    if (this.form.valid) {
      this.formSubmit.emit(body);
    } else {
      this.errors = this.formService.getErrors(this.form);
      this.error = this.errors[0];
      this.formService.markFormGroupTouched(this.form);
    }
  }
}
