import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {IProductItem} from "../../types/Entities";
import {CartService} from "../../services/cart.service";
import {TransportService} from "../../services/transport.service";

@Component({
  selector: 'app-fund-cart-item',
  templateUrl: './fund-cart-item.component.html',
  styleUrls: ['./fund-cart-item.component.styl']
})
export class FundCartItemComponent implements OnInit {
  @Input() data: IProductItem;
  @Input() fee = 0;
  @Output() oneTimeDonation = new EventEmitter<IProductItem>();
  @Output() cancelRecuringDonations = new EventEmitter<IProductItem>();
  @Output() renew = new EventEmitter<IProductItem>();
  @Output() paused = new EventEmitter<IProductItem>();

  public activeDisabled = false;
  public active: boolean;
  public showPopover = false;

  constructor(public cart: CartService, private api: TransportService) {
  }

  ngOnInit() {
    this.active = this.data.status === 'active';
  }

  onDotsClick() {
    this.showPopover = !this.showPopover;
  }

  onOneDonationClick() {
    this.cart.amountInputPopup$.next({show: true, item: {...this.data, type: 'single', id: undefined}});
    this.oneTimeDonation.emit(this.data);
  }

  onCancelRecuringDonations() {
    this.api.cancelRecuringDonations(this.data.id).subscribe(() => {
      this.cancelRecuringDonations.emit(this.data);
    });
  }

  onSwitchActive(active) {
    this.activeDisabled = true;
    if (active) {
      this.api.renewRecuringDonations(this.data.id).subscribe(
        () => {
          this.renew.emit(this.data);
          this.activeDisabled = false;
        },
        () => {
          this.active = false;
          this.activeDisabled = false;
        });
    } else {
      this.api.pauseRecuringDonations(this.data.id).subscribe(
        () => {
          this.paused.emit(this.data);
          this.activeDisabled = false;
        },
        () => {
          this.active = true;
          this.activeDisabled = false;
        });
    }
  }
}
