import { Pipe, PipeTransform } from '@angular/core';
import {IOrganisation} from '../../types/User';
import {IProject} from '../../types/IProject';

@Pipe({
  name: 'projectOrgSectorsFilter'
})
export class ProjectOrgSectorsFilterPipe implements PipeTransform {

  transform(value: (IOrganisation | IProject)[], ids: string[]): (IOrganisation | IProject)[] {
    if (value && value.length && ids.length) {
      return value.filter(item => {
        return item.sectors.some(s => ids.some(id => id === s.id));
      });
    } else {
      return value;
    }
  }
}
