import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {IUser} from "../../../types/User";
import {FormService} from "../../../services/form.service";
import {ValidationService} from "../../../services/validation.service";

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './auth-sign-in.component.html',
  styleUrls: ['./auth-sign-in.component.styl']
})
export class AuthSignInComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<IUser>();
  @Output() forgot = new EventEmitter<IUser>();

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });
  public isDirty = false;
  @Input() errors;
  @Input() error;
  constructor(public fb: FormBuilder, public formService: FormService) { }

  ngOnInit() {
  }

  onSubmit() {
    const body = Object.assign({}, this.form.value);
    this.isDirty = true;
    if (this.form.valid) {
      this.formSubmit.emit(body);
    } else {
      this.errors = this.formService.getErrors(this.form);
      this.error = this.errors[0];
      this.formService.markFormGroupTouched(this.form);
    }
  }

  onForgotClick() {
    this.forgot.emit(Object.assign({}, this.form.value));
  }
}
