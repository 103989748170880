import {NgModule} from '@angular/core';
import {MultilangFieldPipe} from './multilang_field.pipe';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";


@NgModule({
  declarations: [
    MultilangFieldPipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    MultilangFieldPipe,

  ],
})

export class LangModule {}
