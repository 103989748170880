import { Pipe, PipeTransform } from '@angular/core';
import {IProductItem, TDonationType} from "../../types/Entities";

@Pipe({
  name: 'filterProductType'
})
export class FilterProductTypePipe implements PipeTransform {

  transform(value: IProductItem[], type: TDonationType): any {
    return value.filter(item => item.type === type);
  }

}
