import { Pipe, PipeTransform } from '@angular/core';
import {ISector, TSectorType} from "../../types/ISector";

@Pipe({
  name: 'sectorType'
})
export class SectorTypePipe implements PipeTransform {

  transform(value: ISector[], type: TSectorType): any {
    return value.filter(s => s.type === type);
  }

}
