import { Pipe, PipeTransform } from '@angular/core';
import {IOrgCartItem} from "../../services/cart.service";
import {TDonationType} from "../../types/Entities";

@Pipe({
  name: 'filterOrgCartItem'
})
export class FilterOrgCartItemPipe implements PipeTransform {

  transform(value: {key: keyof IOrgCartItem, value: IOrgCartItem}[], type: TDonationType): any {
    return value.filter(item => item.value[type === 'single' ? 'single_amount' : 'recuring_amount'] > 0);
  }

}
