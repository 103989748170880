import {FormControl, FormGroup} from '@angular/forms';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {isValid, parse} from "date-fns";

// custom validator to check that two fields match
export function validateDtFactory() {
  return (formControl: FormControl) => {

    // return null if controls haven't initialised yet
    if (!formControl.value) {
      return null;
    }

    // return null if another validator has already found an error on the matchingControl
    if (formControl.errors && !formControl.errors.dtValidator) {
      return null;
    }

    // set error on matchingControl if validation fails
    if (formControl.value.match(/^\d{4}-\d{2}-\d{2}/) && isValid(new Date(formControl.value))) {
      // formControl.setErrors(null);
      return null;
    } else {
      // formControl.setErrors({phoneValidator: true});
      return {dtValidator: true};
    }
  };
}
