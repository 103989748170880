import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Subject} from "rxjs";
import {ILazyLoadElInit} from "./lazy-load-entities";
import {LoggerService} from "../../services/logger.service";

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {
  public elInitSource = new Subject<ILazyLoadElInit>();
  private elInit$ = this.elInitSource.asObservable();
  private elIntersectSource = new Subject<ILazyLoadElInit>();
  public elIntersect$ = this.elIntersectSource.asObservable();
  public intObserver: IntersectionObserver;
  public elements: ILazyLoadElInit[] = [];

  constructor(
    private logger: LoggerService
  ) {
    this.elInit$.subscribe(el => {
      this.intObserver = this.intObserver || new IntersectionObserver(
        this.intObserverCb.bind(this),
        {threshold: 0, rootMargin: '400px'}
      );
      this.elements.push(el);
      this.intObserver.observe(el.ref.nativeElement);
      // this.logger.l('LazyLoadService elInit$', el);
    });
    this.logger.l('LazyLoadService', this);
  }

  intObserverCb(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const elIndex = this.elements.findIndex(e => e.ref.nativeElement === entry.target);
        if (elIndex >= 0) {
          const el = this.elements[elIndex];
          const $el = el.ref.nativeElement;
          observer.unobserve($el);
          this.elIntersectSource.next(el);
          this.elements.splice(elIndex, 1);
          // this.logger.l('LazyLoadService entry isIntersecting', el);
        }
      }
    });
  }
}
