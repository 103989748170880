import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {CartService, IAmountInputPopup} from "../../services/cart.service";

@Component({
  selector: 'app-amount-input-popup',
  templateUrl: './amount-input-popup.component.html',
  styleUrls: ['./amount-input-popup.component.styl']
})
export class AmountInputPopupComponent implements OnInit {
  public popupSubscription: Subscription;
  public data: IAmountInputPopup;
  public value: number;

  constructor(public cart: CartService, private router: Router) { }

  ngOnInit() {
    this.popupSubscription = this.cart.amountInputPopup$.subscribe((data) => {
      this.data = data;
      // if (data.show) {
      //   this.cart.addItem(data.item);
      // } else {
      //   this.cart.removeItem(data.item);
      // }
    });
  }

  onSubmit() {
    this.data.show = false;
    this.cart.addItem({...this.data.item, amount: this.value});
    this.router.navigate(['/checkout']);
  }

  onCancel() {
    this.data.show = false;
    // this.cart.removeItem(this.data.item);
  }
}
