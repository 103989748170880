import {Component, Input, OnInit} from '@angular/core';
import {IHeaderInputs} from "../header/header.component";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.styl']
})
export class LayoutComponent implements OnInit {
  @Input() theme;
  @Input() bg;
  @Input() bgTheme;
  @Input() headImage;
  @Input() headImageTheme;
  @Input() headerInputs: IHeaderInputs;
  @Input() cartPanel = true;

  constructor() { }

  ngOnInit() {
  }

}
