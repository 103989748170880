import {parsePhoneNumberFromString} from 'libphonenumber-js';

export class ValidationService {
  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return {'invalidCreditCard': true};
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])/)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
    if (control.value.match(/^(?=.*[0-9]).{6,100}$/)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  static numberValidator(control) {
    if (control.value.match(/^\d+$/)) {
      return null;
    } else {
      return {'invalidNumber': true};
    }
  }

  static phoneValidator(control) {
    if (control.value) {
      const value = ValidationService.trimPhone(control.value);
      const parsedValue = parsePhoneNumberFromString(value);

      return parsedValue && parsedValue.isValid() ? null : {'invalidPhoneValidator': true};
    }
  }

  static trimPhone(value: string) {
    return value.replace(/[^\+\d]/g, '')
      .replace(/^8/, '+7')
      .replace(/^9/, '+79')
      .replace(/^([^+])/, '+$1');
  }

  static dtValidator(control) {
    if (control.value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return null;
    } else {
      return {'invalidDt': true};
    }
  }
}
