import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
  Renderer2,
  AfterViewInit, OnDestroy
} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  constructor(private _elementRef : ElementRef, private renderer: Renderer2) {
  }

  @Output()
  public clickOutside = new EventEmitter();
  private listener;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.listener = this.renderer.listen('document', 'click', (e) => {
        const clickedInside = this._elementRef.nativeElement.contains(e.target);
        if (!clickedInside && e.target !== this._elementRef.nativeElement) {
          this.clickOutside.emit(null);
        }
      });
    }, 200);
  }

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener();
    }
  }
}
