import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {delay} from "rxjs/operators";
import {IOrganisation} from "../../types/User";
import {IMember} from "../../types/IMember";
import {TransportService} from "../../services/transport.service";
import {ActivatedRoute} from "@angular/router";
import {IProject} from "../../types/IProject";
import {ISector} from "../../types/ISector";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.styl']
})
export class ProjectComponent implements OnInit {
  public projects: IProject[];
  public data: IProject;
  public sectors: ISector[];
  public donations: any[];
  public members: IMember[];
  public donationsLimit = 9;
  public pageLimit = 2;
  public currPage = 0;
  public showLoader = false;
  public disableInfiniteScroll = true;

  constructor(private api: TransportService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.api.getProjects({id: params.id})
        .toPromise().then((data) => this.data = data as IProject);
      this.getSimilarProjects(params.id).subscribe(data => this.projects = data[0] as IProject[]);
      this.api.getMembers(params.id)
        .toPromise().then((data) => this.members = data[0] as IMember[]);
    });
  }

  getSimilarProjects(id?: string): Observable<(IProject[] | number)[]> {
    return this.api.getSimilarProjects(this.data && this.data.id ? this.data.id : id, {limit: this.pageLimit, offset: this.currPage});
    // .subscribe(data => this.projects = data[0] as IProject[]);
  }

  infiniteScroll() {
    this.showLoader = true;
    this.currPage++;
    this.disableInfiniteScroll = true;
    this.getSimilarProjects().subscribe((data) => {
      const items = data[0] as IProject[];
      const count = data[1];
      if (items && items.length) {
        this.projects = [...this.projects, ...items];
      }
      this.showLoader = false;
      this.disableInfiniteScroll = this.projects.length === count;
    });
  }
}
