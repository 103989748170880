import {
  Component,
  ElementRef, EventEmitter,
  Host,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlContainer, NgForm} from "@angular/forms";
import {IMember} from "../../types/IMember";
import {IFormDataFile, TFileTypes} from "../../types/Entities";
import {FsService} from "../../services/fs.service";
import {clone} from 'ramda';
import {LoggerService} from "../../services/logger.service";
import {Observable, Subscription} from "rxjs";

export interface IMemberValueChange {
  index: number;
  key: keyof IMember;
  value: string;
}

@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.styl']
})
export class ProjectMembersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() members: IMember[] = [];
  @Input() disabled = false;
  @Input() setMembers$: Observable<IMember[]>;
  @Input() addMember$: Observable<IMember>;
  @Input() removeMember$: Observable<IMember>;
  @Input() patchMember$: Observable<IMember>;
  @Output() addMember = new EventEmitter<IMember>();
  @Output() removeMember = new EventEmitter<number>();
  @Output() changeMember = new EventEmitter<IMember>();
  @Output() changeMemberValue = new EventEmitter<IMemberValueChange>();
  @Output() changeMembers = new EventEmitter<IMember[]>();
  @Output() changeFile = new EventEmitter<IFormDataFile>();

  public subscriptions: Subscription[] = [];
  public formSubject: IMember[];
  public newMember: IMember = {
    fullName: '',
    role: '',
    image: null
  };
  // public newMemberImage: IFormDataFile;

  constructor(private fsService: FsService, private logger: LoggerService) { }

  ngOnInit() {
    this.formSubject = clone(this.members);
    if (this.addMember$) {
      this.subscriptions.push(
        this.addMember$.subscribe((member: IMember) => {
          this.formSubject.push(member);
        })
      );
    }
    if (this.removeMember$) {
      this.subscriptions.push(
        this.removeMember$.subscribe((member: IMember) => {
          this.formSubject.splice(
            this.formSubject.findIndex((m) => m.id === member.id), 1
          );
        })
      );
    }
    if (this.setMembers$) {
      this.subscriptions.push(
        this.setMembers$.subscribe((members: IMember[]) => {
          this.formSubject = clone(members);
        })
      );
    }
    if (this.patchMember$) {
      this.subscriptions.push(
        this.patchMember$.subscribe((member: IMember) => {
          const index = this.formSubject.findIndex((m) => m.id === member.id);
          const currentMember = this.formSubject[index];
          this.formSubject[index] = {...currentMember, ...member};
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.members) {
      this.members = changes.members.currentValue;
      this.formSubject = clone(changes.members.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  onFileChange(files: File[], member: IMember) {
    if (files && files[0]) {
      const data: IFormDataFile = {file: files[0], ownerId: member.id, type: 'member-image'};
      member.image = data;
      if (member !== this.newMember) {
        this.changeFile.emit(data);
      }
      this.changeImage(data.file, member);
      this.logger.l('member file change', member);
    }
  }

  changeImage(file: File, member: IMember) {
    member.image.url = this.getImageRef(file);
  }

  getImageRef(file): string {
    return this.fsService.blobUrlRef(file);
  }

  onAddMember() {
    if (this.newMember.fullName) {
      const member = clone(this.newMember);
      this.addMember.emit(member);
      this.newMember = {fullName: '', role: '', image: null};
      this.logger.l('add member', member);
    }
  }

  onChangeMemberValue(e, index: number, key: keyof IMember) {
    this.changeMemberValue.emit({index, key, value: e.target.value} as IMemberValueChange);
  }

  onRemoveMember(index: number) {
    if (this.disabled) return;
    this.formSubject.splice(index, 1);
    this.removeMember.emit(index);
  }
}
