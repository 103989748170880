import {FormControl, FormGroup} from '@angular/forms';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

// custom validator to check that two fields match
export function validatePhoneFactory() {
  return (formControl: FormControl) => {
    const trimPhone = (value: string) => {
      return value.replace(/[^\+\d]/g, '')
        .replace(/^8/, '+7')
        .replace(/^9/, '+79')
        .replace(/^([^+])/, '+$1');
    };

    // return null if controls haven't initialised yet
    if (!formControl.value) {
      return null;
    }

    // return null if another validator has already found an error on the matchingControl
    if (formControl.errors && !formControl.errors.phoneValidator) {
      return null;
    }

    // set error on matchingControl if validation fails
    const phone = trimPhone(formControl.value);
    const parsedValue = parsePhoneNumberFromString(phone);

    if (parsedValue && parsedValue.isValid()) {
      // formControl.setErrors(null);
      return null;
    } else {
      // formControl.setErrors({phoneValidator: true});
      return {phoneValidator: true};
    }
  };
}
