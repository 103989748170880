import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent} from '@angular/common/http';
import {GlobalService} from '../services/global.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../services/storage.service';
import {get as getCookie, set as setCookie} from 'js-cookie';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  status = '';

  constructor(private global: GlobalService,
              private translate: TranslateService, private storage: StorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = getCookie('x-access-token');
    const body = {_token: true, ...request.body};
    if (
      token && body._token
      // !(request.method === 'POST' && request.url.match(//))
    ) {
      request = request.clone({
        setHeaders: {
          'x-access-token': token
        }
      });
    }

    return next.handle(request);
  }
}
