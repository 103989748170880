import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

export interface ISwitchTabsControl {
  name: string;
  value: any;
}

@Component({
  selector: 'app-switch-tabs',
  templateUrl: './switch-tabs.component.html',
  styleUrls: ['./switch-tabs.component.styl']
})
export class SwitchTabsComponent implements OnInit, OnChanges {
  @Input() sz: string;
  @Input() theme: string;
  @Input() mr: string;
  @Input() controls: ISwitchTabsControl[];
  @Input() disabled = false;
  @Input() active: any;
  @Input() required = false;
  @Output() changeControl = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes && changes.active) {
    //   if (changes.active.currentValue !== this.active) {
    //     this.active = changes.active.currentValue;
    //   }
    // }
  }

  changeTab(tab: ISwitchTabsControl) {
    if (this.disabled) return;
    this.active = this.required ? tab.value : this.active === tab.value ? null : tab.value;
    this.changeControl.emit(this.active);
  }
}
