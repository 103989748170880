import {Component, OnDestroy, OnInit} from '@angular/core';
import {TransportService} from "../../services/transport.service";
import {CartService} from "../../services/cart.service";
import {AuthService} from "../../services/auth.service";
import {IUser} from "../../types/User";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.styl']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public isLoggedIn = false;
  constructor(public cart: CartService, public authService: AuthService, private api: TransportService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.isLoggedIn().subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        this.init();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  init() {
    this.cart.sync();
    this.subscriptions.push(
      this.cart.init$.subscribe(() => {
        this.cart.sync();
      }),
      this.cart.addRemoveItems$.subscribe((data) => {
        this.cart.syncItem(data.item).toPromise();
      })
    );
  }

  checkout(body: IUser) {
    this.cart.checkout(body).toPromise();
    // if (!this.isLoggedIn) {
    //   this.authService.signUp(data).subscribe(() => {
    //     this.cart.checkout().toPromise();
    //   });
    // } else {
    //   this.cart.checkout().toPromise();
    // }
  }
}
