import {Component, OnDestroy, OnInit} from '@angular/core';
import {IUser} from "../../types/User";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {TransportService} from "../../services/transport.service";
import {Subscription} from "rxjs";

export interface IAuthState {
  tab?: 'sign_up' | 'sign_in' | 'forgot';
  step?: 'enter' | 'reset' | 'ok' | 'finish' | 'password';
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.styl']
})
export class AuthComponent implements OnInit, OnDestroy {
  public initialState: IAuthState = {
    tab: 'sign_in',
    step: 'enter'
  };
  public state: IAuthState = this.initialState;
  public error;
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute, public authService: AuthService, private api: TransportService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.authState$.subscribe((state: IAuthState) => {
        this.setState(state);
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }
  onSignUpSubmit(value: IUser) {
    this.authService.signUp(value).subscribe(() => {
      this.setState({tab: 'sign_up', step: 'ok'});
    });
  }
  onSignInSubmit(value: IUser) {
    this.authService.signIn(value).subscribe(() => {
      this.error = null;
      this.authService.closePopup();
    }, () => {
      this.error = {name: 'rejected'};
    });
  }

  onSignUpPasswordSubmit(value: IUser) {
    this.setPassword(value);
  }

  onForgetPasswordSubmit(value) {
    this.authService.forgetPassword(value).subscribe(() => {
      this.setState({tab: 'forgot', step: 'ok'});
    });
  }

  onForgetResetSubmit(value) {
    this.setPassword(value, {tab: 'forgot', step: 'finish'});
  }

  setPassword(value: IUser, step: IAuthState = {tab: 'sign_up', step: 'finish'}) {
    this.authService.setPassword({
      password: value.password,
      setupPasswordToken: this.authService.user.setupPasswordToken
    }).subscribe(() => {
      this.setState(step);
    });
  }

  onForgotClick() {
    this.setState({tab: 'forgot', step: 'enter'});
  }

  setState(state: IAuthState) {
    this.state = {...this.state, ...state};
  }

  setInitialState() {
    this.setState(this.initialState);
  }
}
