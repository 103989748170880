import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {LoggerService} from "../services/logger.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // if (err.status === 401) {
      //   // auto logout if 401 response returned from api
      //   location.reload(true);
      // }

      // const error = err.error.message || err.statusText;
      // this.logger.e('ErrorInterceptor', err);
      return throwError(err);
    }));
  }
}
