import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isValid, parse} from "date-fns";

@Component({
  selector: 'app-date-inputs',
  templateUrl: './date-inputs.component.html',
  styleUrls: ['./date-inputs.component.styl']
})
export class DateInputsComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() value: string;
  @Input() required = false;
  @Input() disabled = false;
  @Output() dateChange = new EventEmitter<string>();

  public date = '';
  public month = '';
  public year = '';
  public currentValue = '';
  public focus: '' | 'date' | 'month' | 'year';
  public valid = true;

  constructor() { }

  ngOnInit() {
    this.currentValue = this.value;
    this.init();
  }

  init() {
    if (this.currentValue) {
      const d  = parse(this.currentValue);
      this.date = d.getDate() > 9 ? `${d.getDate()}` : `0${d.getDate()}`;
      this.month = d.getMonth() > 9 ? `${d.getMonth()}` : `0${d.getMonth()}`;
      this.year = `${d.getFullYear()}`;
    } else {
      this.date = '';
      this.month = '';
      this.year = '';
      this.currentValue = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.value) {
      if (changes.value.currentValue !== this.currentValue) {
        this.currentValue = changes.value.currentValue;
        this.focus = '';
        this.init();
        this.checkValid(this.currentValue);
      }
    }
  }

  onDateChange(e, name: 'date' | 'month' | 'year', maxLength: number) {
    const value = e.target.value;

    this.focus = name;
    if (value.length >= maxLength && value !== this[name]) {
      this.focus = name === 'date' ? 'month' : name === 'month' ? 'year' : this.focus;
    }
    // if (this[name].length === 0 && value.length === 0) {
    //   this.focus = name === 'year' ? 'month' : name === 'month' ? 'date' : this.focus;
    // }
    this[name] = value;
    this.currentValue = `${this.year}-${this.month}-${this.date}`;
    this.checkValid(this.currentValue);
    this.dateChange.emit(this.date || this.month || this.year ? this.currentValue : null);
  }

  checkValid(value) {
    this.valid = (!this.required ? (this.date || this.month || this.year) : true) ? !!value.match(/^\d{4}-\d{2}-\d{2}/) && isValid(new Date(value)) : true;
  }
}
