import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {IOrganisation, IUser} from "../types/User";
import {Observable, of, ReplaySubject, Subject, throwError} from "rxjs";
import {IAuthState} from "../components/auth/auth.component";
import {TransportService} from "./transport.service";
import {catchError, distinctUntilChanged, finalize, map, mergeMap, shareReplay, switchMap, tap} from "rxjs/operators";
import {ISetupPassword} from "../types/ISetupPassword";
import {IConfirmEmail} from "../types/IConfirmEmail";
import {IConfirmEmailRes} from "../types/IConfirmEmailRes";
import {StorageService} from "./storage.service";
import {addMinutes} from "date-fns";
import {get as getCookie, set as setCookie, remove as removeCookie} from 'js-cookie';
import {LoggerService} from "./logger.service";
import {clone} from 'ramda';
import {Router} from "@angular/router";
import {IFormDataFile} from "../types/Entities";
import {FormService} from "./form.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private user$: BehaviorSubject<IUser>;
  // public user: Observable<IUser>;
  public user: IUser;
  public organisation: IOrganisation;
  public userAvatar: IFormDataFile;
  private token: string;
  private guestToken: string;
  public showHidePopup$ = new BehaviorSubject<boolean>(false);
  public authState$ = new ReplaySubject<IAuthState>();
  public tokenExpires: number | Date; // days or Date
  public guestTokenExpires: number | Date; // days or Date

  private userSource = new Subject<IUser | IOrganisation>();
  public user$ = this.userSource.asObservable();
  private userAvatarSource = new BehaviorSubject<IFormDataFile>(null);
  public userAvatar$ = this.userAvatarSource.asObservable();
  private authSource = new Subject<void>();
  public auth$ = this.authSource.asObservable();
  private signInSource = new Subject<IUser | IOrganisation>();
  public signIn$ = this.signInSource.asObservable();
  private logoutSource = new Subject<void>();
  public logout$ = this.logoutSource.asObservable();
  private getUser$: Observable<IUser>;
  private isLoggedInSource = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSource.asObservable();
  private changePasswordPopupSource = new BehaviorSubject<boolean>(false);
  public changePasswordPopup$ = this.changePasswordPopupSource.asObservable();

  constructor(private api: TransportService,
              private storage: StorageService,
              private logger: LoggerService,
              private router: Router,
              private formService: FormService) {
    const token = getCookie('x-access-token');
    this.tokenExpires = 1;
    this.guestTokenExpires = 1;
    this.token = token;

    this.getAndSetUser().subscribe(() => {
      this.isLoggedIn().toPromise().then((isLoggedIn) => this.isLoggedInSource.next(isLoggedIn));
      this.logger.l('user init', this.user);
    });

    this.logout$.subscribe(() => {
      this.getAndSetUser().toPromise();
    });
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getUser()
      .pipe(
        mergeMap((user: IUser) => {
          return of(user && user.role !== 'guest');
        }),
        catchError(() => of(false)),
      );
  }

  public getUser(reset = false, pipe = true): Observable<IUser | IOrganisation> {
    if (this.user && !reset) {
      return of(clone(this.user));
    } else if (this.getUser$) {
      return this.getUser$;
    } else {
      this.getUser$ = this.api.getUser().pipe(
        map(data => {
          return pipe ? ((data as IOrganisation).user || data) : data;
        }),
        shareReplay(1),
        finalize(() => {
          this.getUser$ = null;
        })
      );
      return this.getUser$;
    }
  }

  public getAndSetUser(): Observable<IUser> {
    return this.getUser(true, false)
      .pipe(
        tap((user) => {
          this.saveUser(user);
          this.logger.l('user', this.user);
          this.logger.l('organisation', this.organisation);
          return of(user);
        }),
        catchError(() => {
          return this.api.signUpGuest()
            .pipe(
              mergeMap((data) => {
                return this.saveGuest(data.token);
              })
            );
        })
      );
  }

  patchUser(data: { user?: IUser, organisation?: IOrganisation } = {}): Observable<IUser | IOrganisation> {
    data.user = data.user || {};
    data.organisation = data.organisation || {};
    return this.api.patchUser({...data.user, organisation: data.organisation})
      .pipe(
        tap(
          (user: IUser | IOrganisation) => {
            this.saveUser({...data.organisation, user: data.user});
          }
        ));
  }

  patchUserAvatar(file: File): Observable<IFormDataFile> {
    return this.api.uploadFile(this.formService.makeFormDataFile({
      type: 'user-avatar',
      file,
      ownerId: this.user.id
    })).pipe(
      map(data => {
        this.userAvatar = data;
        this.userAvatarSource.next(clone(data));
        return data;
      })
    );
  }

  getUserAvatar(): Observable<IFormDataFile> {
    return this.getUser().pipe(
      mergeMap(
        (user) => {
          if (this.userAvatar && this.userAvatar.ownerId === user.id) {
            return of(this.userAvatar);
          } else {
            return this.api.getFiles(user.id).pipe(
              map(data => {
                const avatar = data.find(image => image.type === 'user-avatar');
                this.userAvatar = avatar;
                return avatar;
              })
            );
          }
        }
      )
    );
  }

  openPopup() {
    this.showHidePopup$.next(true);
  }

  closePopup() {
    this.showHidePopup$.next(false);
  }

  closePopupForConfirm() {
    this.showHidePopup$.next(false);
    this.authState$.next({tab: 'sign_in', step: 'enter'});
  }

  setState(state: IAuthState) {
    this.authState$.next(state);
  }

  signUp(body: IOrganisation | IUser) {
    return this.api.signUp(body);
  }

  signIn(body: IUser) {
    return this.api.signIn(body).pipe(map(res => {
      this.saveToken((res as IUser).token);
      // window.location.reload();
      this.getAndSetUser().toPromise().then(() => {
        this.signInSource.next();
        this.authSource.next();
        this.isLoggedInSource.next(true);
        // this.isLoggedIn().toPromise().then(isLoggedIn => this.isLoggedInSource.next(isLoggedIn));
      });
    }));
  }

  forgetPassword(body) {
    return this.api.forgetPassword(body);
  }

  saveUser(data) {
    const user = clone(data.user || data);
    const org = data.user ? clone(data) : null;
    this.user = this.user ? {...this.user, ...user} : user;
    this.storage.setItem('user', JSON.stringify(this.user));

    if (org) {
      // delete org.user;
      this.organisation = this.organisation ? {...this.organisation, ...org} : org;
      this.storage.setItem('organisation', JSON.stringify(data));
    }
    if (this.user.role !== 'guest') {
      this.guestToken = undefined;
    }
    this.userSource.next(data);
  }

  saveToken(token: string) {
    this.token = token;
    setCookie('x-access-token', token, {
      expires: this.tokenExpires
    });
  }

  saveGuest(token: string): Observable<IUser> {
    this.guestToken = token;
    // this.token = token;
    setCookie('x-access-token', token, {
      expires: this.guestTokenExpires
    });
    return this.api.getUser().pipe(tap((user) => this.saveUser(user)));
  }

  confirmEmail(body: IConfirmEmail) {
    return this.api.confirmEmail(body)
      .pipe(
        map((res: IConfirmEmailRes) => {
          this.saveUser(res);
          return res;
        })
      );
  }

  setPassword(body: ISetupPassword) {
    return this.api.setPassword(body);
  }

  changePassword(password) {
    return this.api.changePassword({password}).pipe(
      tap(data => {
        this.saveToken(data.token);
      })
    );
  }

  openChangePasswordPopup() {
    this.changePasswordPopupSource.next(true);
  }

  closeChangePasswordPopup() {
    this.changePasswordPopupSource.next(false);
  }

  logout(redirect = false) {
    if (redirect) {
      this.router.navigate(['/']);
    }
    this.storage.removeItem('user');
    removeCookie('x-access-token');
    this.isLoggedInSource.next(false);
    this.user = null;
    this.organisation = null;
    this.token = null;
    this.logoutSource.next();
    this.authSource.next();
    // window.location.reload();
  }
}

// post<T exteds Object>(): Observable<T>
