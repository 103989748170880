import { Pipe, PipeTransform } from '@angular/core';
import {IFormDataFile, TFileStatuses, TFileTypes} from '../../types/Entities';

@Pipe({
  name: 'imageType'
})
export class ImageTypePipe implements PipeTransform {

  transform(value: IFormDataFile[], type: TFileTypes, status: TFileStatuses = 'APPROVED'): IFormDataFile {
    return value && value.length ? value.find(f => {
      return f.type === type && f.status === status;
    }) || {type} : {type};
  }

}
