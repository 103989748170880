import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {IOrganisation, TOrgSkin} from "../../types/User";
import {IFormDataFile, ILink, IProductItem} from "../../types/Entities";
import {CartService} from "../../services/cart.service";
import {Subject} from "rxjs";
import {SupportComponent} from "../support/support.component";

@Component({
  selector: 'app-fund-item',
  templateUrl: './fund-item.component.html',
  styleUrls: ['./fund-item.component.styl']
})
export class FundItemComponent implements OnInit, AfterViewInit {
  @Input() data: IOrganisation;
  @Input() recurring: IProductItem;
  @Input() support = true;
  @Input() skin: TOrgSkin;
  @Input() logo: IFormDataFile;
  @Input() cover: IFormDataFile;
  @Input() links: ILink[];
  @Input() replaceRecurring = true;
  @Input() sz = '';
  @Output() renew = new EventEmitter<IProductItem>();
  @Output() paused = new EventEmitter<IProductItem>();
  @ViewChild(SupportComponent, {static: false}) supportRef;
  public viewInitSource = new Subject<void>();
  public viewInit$ = this.viewInitSource.asObservable();

  constructor(public global: GlobalService, public cart: CartService) {
  }

  ngOnInit() {
    this.setRecurring();
  }

  ngAfterViewInit(): void {
    this.viewInitSource.next();
  }

  setRecurring() {
    if (this.replaceRecurring) {
      this.cart.getRecurringDonations().toPromise().then((items: IProductItem[]) => {
        this.recurring = this.cart.getRecurring(this.data, items);
      });
    }
  }
}
