import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IUser} from "../../../types/User";
import {FormBuilder, Validators} from "@angular/forms";
import {FormService} from "../../../services/form.service";
import {ValidationService} from "../../../services/validation.service";

@Component({
  selector: 'app-auth-forget-enter',
  templateUrl: './auth-forget-enter.component.html',
  styleUrls: ['./auth-forget-enter.component.styl']
})
export class AuthForgetEnterComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<IUser>();
  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  public isDirty = false;

  constructor(
    public fb: FormBuilder,
    public formService: FormService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    const body = Object.assign({}, this.form.value);
    this.isDirty = true;
    if (this.form.valid) {
      this.formSubmit.emit(body);
    } else {
      this.formService.markFormGroupTouched(this.form);
    }
  }
}
