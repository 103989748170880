import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {ISupportForm} from "../types/Entities";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.styl']
})
export class ContactsComponent implements OnInit, OnDestroy {
  public formSubject: ISupportForm;
  private subscriptions: Subscription[] = [];
  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.setForm();
    this.subscriptions.push(
      this.auth.auth$.subscribe(() => {
        this.setForm();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  setForm() {
    this.auth.getUser().toPromise().then((user) => this.formSubject = {firstName: '', lastName: '', message: '', ...user});
  }
}
