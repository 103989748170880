import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {CartService} from "../../../services/cart.service";
import {animate, style, transition, trigger, useAnimation} from "@angular/animations";
import animateScrollTo from "animated-scroll-to";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../services/global.service";
import {expandAnimationHide, expandAnimationShow} from "../../../animations";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-cart-panel-cart',
  templateUrl: './cart-panel-cart.component.html',
  styleUrls: ['./cart-panel-cart.component.styl'],
  animations: [
    trigger('showHideArticleTrigger', [
      transition(':enter', [
        useAnimation(expandAnimationShow, {params: {timings: '600ms ease-in-out'}})
      ]),
      transition(':leave', [
        useAnimation(expandAnimationHide, {params: {timings: '600ms ease-in-out'}})
      ]),
    ])
  ]
})
export class CartPanelCartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() rect;
  @ViewChild('panel', {static: false}) panel: ElementRef;
  public showArticle = false;
  public subscribtions: Subscription[] = [];

  constructor(
    public cart: CartService,
    private elRef: ElementRef,
    private translate: TranslateService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {
    // this.subscribtions.push(
    //   this.cart.addRemoveItems$.subscribe(() => {
    //     this.changeView().then(() => {
    //       this.checkAndSetPosition();
    //     });
    //   })
    // );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.changeView().then(() => {
        this.checkAndSetPosition();
      });
    }, 200);
  }

  ngOnDestroy(): void {
    this.cart.cartPanelViewChange$.next(null);
    this.subscribtions.map(s => s.unsubscribe());
  }

  checkAndSetPosition() {
    if (this.rect) {
      const $panel: HTMLElement = this.elRef.nativeElement;
      const $b = ($panel.children[0] as HTMLElement);
      const rectPos = this.rect.top + this.rect.height;
      if (rectPos >= $b.offsetTop) {
        const result = pageYOffset + (rectPos - $b.offsetTop);
        // let wait;
        // if (result >= document.body.clientHeight) {
        //   wait = setInterval(() => {
        //     console.log('wait');
        //     if (result < document.body.clientHeight) {
        //       clearInterval(wait);
        //       animateScrollTo(result);
        //     }
        //   }, 200);
        // } else {
        animateScrollTo(result);
        // }
      }
    }
  }

  changeView(): Promise<void> {
    return new Promise<void>(resolve => {
      this.cart.cartPanelViewChange$.next
      (
        {$el: this.panel.nativeElement, rect: this.panel.nativeElement.getBoundingClientRect()}
      );
      resolve();
    });
  }

  onDrop() {
    this.translate.get('Cart.ClearConfirm').toPromise().then((question) => {
      if ((this.global.isDesktop && window.confirm(question)) || !this.global.isDesktop) {
        this.cart.drop(true);
      }
    });
  }
}
