import {INavLink} from "../types/Entities";

export const links: INavLink[] = [
  {
    name: {
      ru: 'Что такое sure',
      en: 'What\'s Sure'
    },
    route: '/about'
  },
  {
    name: {
      ru: 'Контакты',
      en: 'Contact'
    },
    route: '/contact'
  }
];
